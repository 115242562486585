import React, { Component } from "react";
import { connect } from "react-redux";
import { IonPage, IonContent } from "@ionic/react";
import {
  Container,
  Button,
  Grid,
  Box,
  Typography,
  createStyles,
  withStyles,
  Link,
} from "@material-ui/core";

import Header from "../common/Header";
// FORMS
import UserRegistration from "../components/Forms/UserRegistration.form";

//IMG
import LogoImg from "./../img/logo-colored.png";

const styles = ({ spacing, palette }) =>
  createStyles({
    container: {
      minHeight: "100vh",
    },
    header: {
      fontFamily: "Arial",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "28px",
      marginBottom: "16px",
    },
    form: {
      paddingRight: spacing(3),
      paddingLeft: spacing(3),
      marginTop: spacing(2),
      marginBottom: spacing(2),
    },

    logo: {
      marginTop: spacing(8),
      padding: spacing(6),
    },

    buttonBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    loginButton: {
      border: "2px solid #000000",
      borderRadius: 0,
      fontWeight: 700,
    },
    headerInfo: {
      fontSize: "16px",
      textAlign: "center",
      margin: 24,
    },
    headerLink: {
      color: "#000",
      fontWeight: "bold",
      textDecoration: "underline",
    },
  });

class LoginPage extends Component {
  state = {
    showRegisterForm: false,
    failedToConnect: true,
  };

  async getFields() {
    try {
      await this.props.getUserFields();
      this.setState({ ...this.state, failedToConnect: false });
    } catch (err) {
      this.setState({ ...this.state, failedToConnect: true });
    }
  }

  async componentDidMount() {
    this.getFields();
  }

  handleLogin = () => {
    this.setState({ ...this.state, showRegisterForm: false });
    this.props.loginUser();
  };

  render() {
    const { showRegisterForm, failedToConnect } = this.state;
    const { classes } = this.props;
    const { translate } = this.props.programStore;

    return (
      <IonPage>
        <IonContent>
          {showRegisterForm && (
            <Header
              title={translate["BACK"]}
              backButton={true}
              backButtonFunction={() =>
                this.setState({ showRegisterForm: false })
              }
            />
          )}
          <Container disableGutters className={classes.container}>
            <Grid container>
              <Grid item xs={12} md={4}>
                <img
                  src={LogoImg}
                  className={classes.logo}
                  alt="Stellar logo"
                />

                <Box className={classes.form}>
                  {!showRegisterForm && (
                    <div>
                      <Typography variant="h5" className={classes.header}>
                        {translate["LOGIN"]}
                      </Typography>
                      <Button
                        onClick={this.handleLogin}
                        variant="outlined"
                        size="large"
                        className={classes.loginButton}
                        fullWidth
                      >
                        {translate["LOGIN"]}
                      </Button>
                      <div className={classes.headerInfo}>
                        {translate["DONT_HAVE_AN_ACCOUNT_YET"]}{" "}
                        <Link
                          className={classes.headerLink}
                          onClick={async () => {
                            if (!failedToConnect) {
                              this.setState({ showRegisterForm: true });
                            } else {
                              await this.getFields();
                            }
                          }}
                        >
                          {translate["REGISTER"]}
                        </Link>
                      </div>
                    </div>
                  )}
                  {showRegisterForm && (
                    <div>
                      {" "}
                      <Typography variant="h5" className={classes.header}>
                        {translate["REGISTER"]}
                      </Typography>
                      <UserRegistration history={this.props.history} />
                    </div>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state) => ({
  programStore: state.programStore,
});

const mapDispatchToProps = ({ authStore, userStore }) => ({
  ...authStore,
  ...userStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LoginPage));
