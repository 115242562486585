import { init } from "@rematch/core";

const store = init({
  models: {
    userStore: require("./user.model").default,
    authStore: require("./auth.model").default,
    uiStore: require("./ui.model").default,
    programStore: require("./program.model").default,
    customerStore: require("./customer.model").default,
    transactionStore: require("./transaction.model").default,
    productStore: require("./product.model").default,
  },
});

export default store;
