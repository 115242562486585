import React, { Component } from "react";
import { connect } from "react-redux";
import { IonPage, IonContent } from "@ionic/react";
import {
  Typography,
  Container,
  withStyles,
  createStyles,
  Paper,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

import Header from "../common/Header";

// FORMS
import UpdatePassword from "../components/Forms/UpdatePassword.form";
import UpdateProfile from "../components/Forms/UpdateProfile.form";

const styles = ({ spacing, palette }) =>
  createStyles({
    listHeader: {
      marginBottom: spacing(2),
      color: grey[700],
      fontWeight: "bold",
    },
    paper: {
      padding: 10,
      margin: "10px 0px",
    },
  });

const ListHeader = withStyles(styles)(({ heading = "", classes }) => {
  return (
    <Typography variant="h6" className={classes.listHeader}>
      {heading}
    </Typography>
  );
});

class SettingsPage extends Component {
  async handleAccountDeactivation() {
    const { postDeactivateAccount } = this.props;

    await this.handleDialogConfirmDeactivation();

    await postDeactivateAccount();

    await this.handleDialogDoneDeactivation();
  }

  render() {
    const { translate } = this.props.programStore;
    const { history } = this.props;
    return (
      <IonPage>
        <Header
          title={translate["SETTINGS"]}
          backButton={true}
          backButtonFunction={() => {
            history.replace("/home", { direction: "forward" });
          }}
        />
        <IonContent className="content">
          <Container>
            <Paper square={true} className={this.props.classes.paper}>
              <ListHeader heading={translate["EDIT_PROFILE"]} />
              <UpdateProfile />
            </Paper>
            <Paper square={true} className={this.props.classes.paper}>
              <ListHeader heading={translate["RESET_PASSWORD"]} />
              <UpdatePassword />
            </Paper>
          </Container>
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state) => ({
  userStore: state.userStore,
  authStore: state.authStore,
  programStore: state.programStore,
});

const mapDispatchToProps = ({ userStore, authStore }) => ({
  ...userStore,
  ...authStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SettingsPage));
