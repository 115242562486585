export default {
  state: {
    isLoading: false,
    toastConfig: {
      isOpen: false,
      onDidDismiss: null,
      message: "",
      duration: 5000,
      position: "bottom",
    },
    dialogConfig: {
      isOpen: false,
      message: "",
      actions: [],
    },
    isPrivateRoute: false,
  },
  reducers: {
    setLoadingAction(state, payload) {
      return {
        ...state,
        isLoading: payload,
      };
    },
    setIsPrivateRoute(state, payload) {
      return {
        ...state,
        isPrivateRoute: payload,
      };
    },
    setToastConfig(state, payload) {
      return {
        ...state,
        toastConfig: {
          ...state.toastConfig,
          ...payload,
        },
      };
    },
    setDialogConfig(state, payload) {
      return {
        ...state,
        dialogConfig: {
          ...state.dialogConfig,
          ...payload,
        },
      };
    },
  },
  effects: (dispatch) => ({
    showLoading(payload) {
      dispatch.uiStore.setLoadingAction(payload);
    },

    showToast(payload) {
      dispatch.uiStore.setToastConfig({ isOpen: false });
      dispatch.uiStore.setToastConfig({
        isOpen: true,
        ...payload,
      });
    },

    setRouteIsPrivate(payload) {
      dispatch.uiStore.setIsPrivateRoute(payload);
    },

    showDialog({ message, actions = [] }) {
      dispatch.uiStore.setDialogConfig({ isOpen: false });
      dispatch.uiStore.setDialogConfig({
        isOpen: true,
        message,
        actions,
      });
    },
    closeDialog(payload) {
      dispatch.uiStore.setDialogConfig({ isOpen: false });
    },
  }),
};
