import { Field, Form, Formik } from "formik";
import React from "react";
import { connect } from "react-redux";
import {
  Button,
  ImageUploadField,
  MobileNumberField,
  NumberField,
  SelectField,
  TextField,
} from "../FormFields";

const FormikForm = ({
  inputClassName,
  buttonSize,
  initialValues = {},
  onSubmitHandler,
  formFields,
  validationSchema,
  customSubmitText,
  children,
  programStore,
  onChangeHcpCode, // TODO: should be more generic
  additionalFieldValid,
}) => {
  const { translate } = programStore;

  /**
   * Handle hcp code change event handler.
   * 
   * @param {String} value 
   */
  const handleChangeHcpCode = (value) => {
    if (typeof onChangeHcpCode === 'function') {
      onChangeHcpCode(value);
    }
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
    >
      {({ setFieldValue, isValid, dirty, isSubmitting }) => {
        return (
          <Form>
            {formFields &&
              formFields.map((field) => {
                let fieldType = {
                  type: "text",
                  component: TextField,
                };
                switch (field.type) {
                  case "text_input":
                  case "email_input":
                    fieldType = {
                      type: "text",
                      component: TextField,
                      upperCase:
                        field.field_type_options &&
                        field.field_type_options.should_uppercase,
                      ...(field.disabled ? { disabled: true } : {}),
                    };

                    // NOTE: Custom component TextField can't bind onChange event.
                    if (field.name === 'hcp_code') {
                      fieldType['onChange'] = handleChangeHcpCode;
                    }
                    break;
                  case "select_dropdown_input":
                    fieldType = {
                      component: SelectField,
                      options:
                        field.field_type_options &&
                        field.field_type_options.select_dropdown_options,
                    };
                    break;
                  case "secure_input":
                    fieldType = {
                      type: "password",
                      component: TextField,
                      upperCase:
                        field.field_type_options &&
                        field.field_type_options.should_uppercase,
                    };
                    break;
                  case "number_input":
                    fieldType = {
                      component: NumberField,
                    };
                    break;
                  case "mobile_number_input":
                    fieldType = {
                      component: MobileNumberField,
                      setFieldValue: setFieldValue,
                      mobilePrefixes:
                        field.field_type_options &&
                        field.field_type_options.mobile_prefixes,
                    };
                    break;
                  case "image_input":
                    fieldType = {
                      component: ImageUploadField,
                      setFieldValue: setFieldValue,
                    };
                    break;
                  default:
                    break;
                }
                return (
                  field.is_visible && (
                    <Field
                      key={field.name}
                      name={field.name}
                      {...fieldType}
                      placeholder={
                        translate[field.placeholder] || field.placeholder
                      }
                      label={
                        translate[field.label] ||
                        field.label ||
                        field.title ||
                        field.placeholder
                      }
                      helperText={field.hint}
                      required={field.is_required}
                      characterLimit={
                        field.field_type_options &&
                        field.field_type_options.character_limit
                      }
                    />
                  )
                );
              })}

            {children}

            {(formFields || children) && (
              <Button
                size={buttonSize}
                type="submit"
                disabled={!dirty || !isValid || isSubmitting || !additionalFieldValid}
                style={{ marginTop: "20px" }}
                fullWidth
              >
                {customSubmitText || translate["SUBMIT"]}
              </Button>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

FormikForm.defaultProps = {
  additionalFieldValid: true,
}

const mapStateToProps = (state) => ({
  programStore: state.programStore,
});

export default connect(mapStateToProps, null)(FormikForm);
