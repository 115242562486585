import React from "react";
import { IonPage, IonContent } from "@ionic/react";
import { connect } from "react-redux";
import { Typography, createStyles, withStyles } from "@material-ui/core";
import Header from "../common/Header";
import TermsAndConditionsContent from "../components/StaticContent/TermsAndConditionsContent";
import { withRouter } from "react-router";

const styles = ({ spacing, palette }) =>
  createStyles({
    root: {
      margin: spacing(2),
      padding: spacing(2),
    },
  });

const TermsAndConditionsPage = ({ classes = {}, programStore, uiStore, location, userStore }) => {
  const { translate } = programStore;
  const { isPrivateRoute } = uiStore;
  /**
   * Render tnc content based on params or user type.
   * 
   * @returns DOM
   */
  const renderContent = () => {
    return <TermsAndConditionsContent /> 
  }
  return (
    <IonPage>
      <Header
        title={translate["TERMS_AND_CONDITIONS"]}
        backButton={!isPrivateRoute}
        backToHome={isPrivateRoute}
      />
      <IonContent className="content">
        <Typography variant="body2" paragraph>
          {renderContent()}
        </Typography>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => ({
  programStore: state.programStore,
  transactionStore: state.transactionStore,
  userStore: state.userStore,
  uiStore: state.uiStore,
});

const mapDispatchToProps = ({ transactionStore, uiStore }) => ({
  ...transactionStore,
  ...uiStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(TermsAndConditionsPage)));
