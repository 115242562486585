const { CONNECT_URLS, PROGRAM_ID } = window.config;
const programBaseUrl = `${CONNECT_URLS.programs}/${PROGRAM_ID}`;
const storageBaseUrl = `${CONNECT_URLS.storage}/${PROGRAM_ID}`;

export default {
  getProgramConfig: async (payload) => {
    return window.apiClient.get(`${programBaseUrl}/config`);
  },
  uploadPhoto: async (payload) => {
    return window.apiClient.post(`${storageBaseUrl}/upload`, payload);
  },
  getTranslation: async (payload) => {
    return window.apiClient.get(`${programBaseUrl}/translations`);
  }
};
