import React from "react";
import { Typography, createStyles, withStyles } from "@material-ui/core";

const styles = ({ spacing }) =>
  createStyles({
    header: {
      fontWeight: 700,
      textAlign: "center",
    },
    terms: {
      margin: spacing(2),
      padding: spacing(2),
    },
    indent: {
      textIndent: "2em",
    },
  });

const TermsAndConditionsContent = ({ classes = {} }) => {
  return (
    <div>
      <Typography
        className={classes.terms}
        align="justify"
        variant="body2"
        paragraph
      >
        <div className={classes.header}>
          <u>
          SYARAT DAN KETENTUAN (PERJANJIAN) UNTUK APOTEKER
          </u>
        </div>
        <br />

        <p>
          Dengan menggunakan aplikasi Stellar (“Aplikasi”) Anda (“Pengguna”) menyetujui syarat dan ketentuan 
          yang ditetapkan di bawah ini, yang telah kami, PT IQVIA Solutions Indonesia (“Perusahaan”), berikan. 
          Jika Anda tidak ingin menyetujui syarat dan ketentuan yang diuraikan (“Syarat dan Ketentuan” atau “Perjanjian”), 
          Anda tidak diizinkan untuk menggunakan Aplikasi ini.
        </p>
        <p>
          Syarat dan Ketentuan dapat berubah dari waktu ke waktu, dan Perusahaan akan memberitahu Anda tentang 
          perubahan ini, dengan terus menggunakan Aplikasi setelah perubahan tersebut berlaku, Anda menyetujui 
          Syarat dan Ketentuan terbaru.
        </p>

        <ol>
            <li>
                <p>Definisi:</p>
                <ol type='a'>
                    <li>
                        “Pasien” adalah seseorang dan/atau pelanggan yang membeli produk kesehatan atas nama Pasien, yang membutuhkan produk kesehatan dan diberikan potongan harga sebagaimana ditetapkan dalam Program ini.
                    </li>
                    <li>
                        “Aplikasi” atau “App” mengacu pada platform Stellar milik Perusahaan untuk memberikan layanan atas nama PT Novartis Indonesia (“Novartis”), yang meliputi SMS, layanan aplikasi pada smartphone, web, dan/atau platform berbasis teknologi lainnya.
                    </li>
                    <li>
                        “Tenaga Kesehatan” berarti apoteker yang terdaftar dan memiliki izin di Indonesia.
                    </li>
                    <li>
                        “Outlet” adalah Apotek, Rumah Sakit, atau instalasi Apotek milik Rumah Sakit yang setuju untuk berpartisipasi dalam Program.
                    </li>
                </ol>
            </li>
            <li>
                <p>Perusahaan ingin bekerja sama dan berkolaborasi dengan Tenaga Kesehatan untuk tujuan penyediaan program pasien digital untuk Pasien Onkologi (“Program”). Program ini ditujukan untuk Pasien yang sedang menjalani pengobatan dengan Kryxana (dengan atau tanpa kombinasi dengan Femara), Jakavi, Rebozet, dan Votrient (“Produk Novartis”) melalui Program berbasis teknologi seluler bernama Stellar. Program ini berupaya untuk mempromosikan keterjangkauan dan aksesibilitas Produk Novartis melalui pemberian potongan harga produk kepada Pasien. Penggantian potongan harga yang diberikan melalui Program ini berlaku untuk perusahaan yang setuju untuk berpartisipasi dalam Program, dan ditujukan untuk pasien dengan pengobatan Produk Novartis sesuai dengan resep dokter. Potongan harga yang diberikan kepada pasien akan ditentukan oleh Novartis dan akan diberikan sesuai dengan urutan kotak yang dibeli oleh pasien.</p>
            </li>
            <li>
                <p></p>
                <p>Pengguna harus memastikan pemeliharaan dan kepatuhan terhadap semua pendaftaran dan persetujuan hukum dan/atau pemerintah yang diperlukan selama jangka waktu Perjanjian ini. Hanya Tenaga Kesehatan yang memenuhi syarat untuk menggunakan Aplikasi.</p>
            </li>
            <li>
                <p>Setiap Pengguna dapat menggunakan akun Aplikasi yang sama untuk maksimal tiga perangkat berbeda dan Pengguna bertanggung jawab penuh atas semua penggunaan akun Pengguna. Pengguna memastikan bahwa perangkat yang digunakan untuk Program ini adalah milik Outlet yang digunakan dalam proses operasional. Segala jenis pelanggaran data Program akan menjadi tanggung jawab Pengguna.</p>
            </li>
            <li>
                <p></p>
                <p>Berdasarkan syarat dan ketentuan ini, Pengguna akan memberikan potongan harga kepada Pasien sebagai bagian dari Program Kepatuhan Pasien yang ditetapkan dalam Program. Pengguna harus menjelaskan Syarat dan Ketentuan yang berlaku kepada Pasien bahwa (i) dengan memberikan nomor ponsel, alamat email (opsional), dan foto kartu identitas untuk keperluan pendaftaran Program ini, (ii) Pasien akan menerima 2 SMS dari Perusahaan, (iii) dengan membalas “YA” pada SMS pertama atau mengklik tombol “Setuju” pada halaman web, Pasien dianggap telah menyetujui Syarat dan Ketentuan yang berlaku untuk Pasien, (iv) SMS kedua akan dikirim oleh Perusahaan untuk memberitahu Pasien tentang hak atas potongan harga, (v) Pasien akan menerima SMS dan/atau email tentang informasi kesehatan dan pengingat kunjungan dokter dan (vi) dengan membalas “STOP” melalui SMS atau mengklik tombol “YA” di halaman web sewaktu-waktu Pasien akan dianggap telah memilih keluar dari Program.</p>
                <p>Pengguna juga harus menjelaskan kepada Pasien bahwa nomor ponsel dan informasi lainnya akan diungkapkan untuk Perusahaan dan digunakan oleh Tenaga Kesehatan dan Perusahaan untuk tujuan penyelenggaraan Program saja.</p>
                <p>SMS atau otorisasi elektronik akan dikirimkan oleh Aplikasi ke nomor ponsel Pengguna dan Pasien yang berisi rincian mengenai potongan harga yang berlaku untuk Pasien sesuai Program yang didaftarkan. Pengguna harus menyelesaikan transaksi dengan menawarkan kepada Pasien potongan harga yang berlaku untuk produk yang terdaftar dalam Program, potongan harga yang ditawarkan akan diberikan secara eksklusif kepada Pengguna melalui mekanisme Program dan Perusahaan tidak akan memberikan potongan harga langsung kepada Pasien.</p>
            </li>
            <li>
                <p>Hak dan Kewajiban Perusahaan.</p>
                <ol type='a'>
                    <li>
                        berhak menentukan produk dan jumlah potongan harga yang diperbolehkan berdasarkan Program yang dapat berubah dari waktu ke waktu.
                    </li>
                    <li>
                        berhak menentukan dan menyelesaikan bahasa dan isi pesan yang berkaitan dengan informasi kesehatan, mengisi ulang pengingat dan rincian potongan harga, serta menentukan frekuensi pengiriman pesan kepada Pasien atas nama Pengguna;
                    </li>
                    <li>
                        berhak menghubungi Pasien sesuai dengan Syarat dan Ketentuan Pasien, dan personel Pengguna secara langsung sehubungan dengan penggunaan dan penerapan Program dan Aplikasi, dan Pengguna tidak boleh membatasi atau menghalangi komunikasi tersebut;
                    </li>
                    <li>
                        bertanggung jawab untuk menyebarluaskan informasi produk dan potongan harga serta mengelola infrastruktur TI.
                    </li>
                    <li>
                        berhak mengakhiri partisipasi Pengguna dalam Program jika Pengguna telah melakukan manipulasi data yang termasuk dan tidak terbatas pada data transaksi Program, data Pengguna, dan data Pasien.
                    </li>
                    <li>
                        berhak mengakhiri partisipasi Pengguna dalam Program jika Pengguna tidak aktif melakukan transaksi selama 4 bulan berturut-turut atau secara sukarela memilih keluar dari Program
                    </li>
                </ol>
            </li>
            <li>
                <p>Hak dan Kewajiban Pengguna:</p>
                <ol type='a'>
                    <li>
                        Outlet dan Pengguna wajib berpartisipasi dalam Program selama Program masih berjalan;
                    </li>
                    <li>
                        menjual dan menyediakan obat yang dibeli oleh pasien terdaftar sesuai dengan Program Pengguna, sesuai dengan semua peraturan dan perundang-undangan yang berlaku;
                    </li>
                    <li>
                        mengizinkan Perusahaan untuk mengirimkan informasi kesehatan dan mengisi ulang pengingat melalui SMS kepada Pasien atas nama Pengguna;
                    </li>
                    <li>
                        harus memastikan bahwa potongan harga yang ditawarkan dalam Program akan ditawarkan berdasarkan otorisasi dari Perusahaan dan sesuai dengan instruksi Perusahaan;
                    </li>
                    <li>
                        Membatalkan transaksi di Aplikasi jika ada kesalahan atau pembatalan dari pasien. Pembatalan transaksi dapat dilakukan dalam waktu 24 jam sejak transaksi; dan
                    </li>
                    <li>
                        harus menjaga kerahasiaan data yang diperoleh dari Pasien dan memastikan bahwa data tersebut tidak dibagikan kepada pihak ketiga selain Perusahaan atau perwakilan pihak ketiga yang diberi wewenang oleh Perusahaan dengan tunduk pada Syarat dan Ketentuan Pasien dan persetujuan yang diperlukan yang diperoleh dari Pasien untuk pengungkapan kepada Perusahaan.
                    </li>
                </ol>
            </li>
            <li>
                <p>Pengguna wajib melakukan pemeriksaan dan mencatat pemeliharaan. Pengguna harus memastikan bahwa Pasien yang berpartisipasi dalam Program memiliki resep yang sah untuk produk kesehatan yang resepnya diwajibkan oleh Hukum yang berlaku. Catatan Pengguna untuk transaksi yang tercakup dalam Program meliputi rincian berikut:</p>
            </li>
            <ol type='a'>
                <li>tanggal transaksi;</li>
                <li>nomor ponsel pasien;</li>
                <li>merek dan dosis produk;</li>
                <li>jumlah kotak yang dibeli oleh Pasien;</li>
                <li>potongan harga yang diberikan kepada Pasien;</li>
                <li>konfirmasi penerimaan resep yang sah; dan</li>
                <li>nomor referensi, jika diperlukan. Pengguna setuju untuk memberikan akses ke catatan ini kepada Perusahaan atau perwakilan pihak ketiga yang diberi wewenang oleh Perusahaan untuk pelaporan dan/atau audit.</li>
            </ol>
            <li>
                <p>
                    Penggantian. Untuk Produk Kryxana (dengan atau tanpa kombinasi Femara), Jakavi, Rebozet, dan Votrient, Potongan harga yang diberikan oleh Pengguna kepada Pasien akan diganti oleh distributor resmi Novartis secara berkala dalam waktu 30 hari. Rekonsiliasi data potongan harga yang diberikan oleh Pengguna kepada Pasien dilakukan oleh Perusahaan secara mingguan diikuti dengan proses penggantian biaya.
                </p>
            </li>
            <li>
                <p>
                    Pembaruan dan Pengakhiran. Pengguna dapat mengakhiri Perjanjian ini dengan berhenti menggunakan Aplikasi. Jika terjadi pengakhiran Perjanjian, Perusahaan dan Novartis melalui distributor akan memberikan kepada Pengguna penggantian atas potongan harga yang belum dibayarkan atau dipindahkan dan Pengguna harus mengembalikan semua materi penjualan yang diberikan oleh Perusahaan. Jika terjadi penipuan oleh Pengguna, Perusahaan dapat segera mengakhiri Perjanjian ini.
                </p>
            </li>
            <li>
                <p>
                    Penyelesaian Sengketa. Semua sengketa, kontroversi, atau klaim (“Sengketa”) antara Para Pihak akan diselesaikan secara damai. Jika setelah jangka waktu tiga puluh (30) hari sejak diterimanya pemberitahuan tertulis tentang adanya Sengketa, Para Pihak gagal mencapai penyelesaian secara damai, maka Sengketa tersebut akan dirujuk dan akhirnya diselesaikan melalui arbitrase di Indonesia sesuai dengan Badan Arbitrase Nasional Indonesia (“BANI”).
                </p>
            </li>
            <li>
                <p>
                    Ganti Rugi. Pengguna harus mengganti kerugian dan tetap mengganti kerugian Perusahaan terhadap setiap dan/atau semua biaya (termasuk biaya pengacara dan biaya hukum), kompensasi, beban, klaim dan denda yang dikeluarkan atau dibayarkan oleh Perusahaan sebagai akibat dari (a) ketidakpatuhan terhadap salah satu ketentuan Perjanjian ini dan/atau undang-undang yang berlaku oleh Pengguna; atau (b) setiap kesalahan atau kelalaian Pengguna.
                </p>
            </li>
            <li>
                <p>
                    Larangan Bersaing. Pengguna tidak boleh secara langsung atau tidak langsung mengadakan dan/atau menggunakan Aplikasi yang serupa atau bersaing dengan orang lain yang menggunakan teknologi seluler untuk mengelola potongan harga dengan cara yang sama atau serupa dengan Program yang saat ini sedang menggunakan Aplikasi tersebut.
                </p>
            </li>
            <li>
                <p>
                    Pengalihan. Perjanjian ini akan mengikat Para Pihak dan penerusnya masing-masing dan pihak yang menerima pengalihan yang diizinkan. Perusahaan berhak untuk mengalihkan hak dan kewajibannya berdasarkan Perjanjian ini kepada orang/entitas lain tanpa perlu mendapatkan persetujuan dari Pengguna. Pengguna tidak boleh mengalihkan hak dan kewajibannya berdasarkan Perjanjian ini kepada orang lain tanpa persetujuan tertulis sebelumnya dari Perusahaan.
                </p>
            </li>
        </ol>
        <br/>
        <div className={classes.header}>
          <u>
            KEBIJAKAN PRIVASI UNTUK TENAGA KESEHATAN
          </u>
        </div>
        <br />
        <p>Perusahaan, PT. IQVIA Solutions, akan mengumpulkan dan menyimpan informasi pribadi Tenaga 
            Kesehatan (sebagaimana ditentukan dalam syarat dan ketentuan) seperti nama, alamat email, 
            nomor ponsel, nama outlet, alamat outlet, nomor akun distributor, nomor surat izin apoteker, kopi 
            dari surat izin apoteker, termasuk informasi pribadi yang sensitive lainnya untuk tujuan berikut:</p>
        <ul>
            <li>Untuk memungkinkan Tenaga Kesehatan berpartisipasi dalam Program Stellar (“Program”);</li>
            <li>Untuk memberikan Tenaga Kesehatan akses untuk menggunakan Aplikasi dan layanan Program;</li>
            <li>Untuk menjawab pertanyaan apa pun yang mungkin Tenaga Kesehatan miliki tentang Program;</li>
            <li>Untuk memungkinkan Perusahaan menghubungi Tenaga Kesehatan yang berkaitan dengan Program;</li>
        </ul>
        <p>“Aplikasi” seperti yang dirujuk di atas adalah platform Stellar yang dimiliki oleh Perusahaan untuk 
            menyediakan layanan dengan atas nama PT Novartis Indonesia (“Novartis”), yang meliputi SMS, 
            layanan aplikasi pada ponsel pintar, situs, dan/atau platform lainnya yang berbasis teknologi. 
            Apoteker setuju untuk mengizinkan Perusahaan memproses informasi pribadi yang berkaitan 
            dengan Apoteker untuk tujuan yang dinyatakan di atas.
        </p>
        <p>Tenaga Kesehatan setuju untuk mengizinkan Perusahaan untuk membagikan atau mengungkapkan 
            informasi pribadi yang dikumpulkan dengan afiliasi, mitra, dan pihak berwenang dari Perusahaan 
            yang berlokasi di dalam dan luar Indonesia untuk tujuan yang disebutkan di atas. Data yang 
            disediakan oleh Tenaga Kesehatan disimpan pada server kami di Singapura.
        </p>
        <p>Sesuai dengan Hukum yang berlaku, Tenaga Kesehatan memiliki hak untuk mengakses, mengoreksi, 
            membatasi, dan menghentikan pemrosesan data oleh Perusahaan atas informasi pribadi Tenaga 
            Kesehatan. Untuk menggunakan hak-hak ini atau mengajukan pertanyaan kepada Perusahaan 
            tentang penggunaan informasi pribadi Tenaga Kesehatan, silakan hubungi Senior Business Solutions 
            Manager dengan mengirim email ke <a href="mailto:stellar.support@psplink.com">stellar.support@psplink.com</a>
        </p>
        <p>Dimana Tenaga Kesehatan berhenti mengizinkan Perusahaan untuk memproses informasi pribadi 
            Tenaga Kesehatan sebagaimana dijelaskan di atas, maka Tenaga Kesehatan tidak dapat 
            berpartisipasi dalam Program.
        </p>
      </Typography>
    </div>
  );
};

export default withStyles(styles)(TermsAndConditionsContent);
