import { withStyles } from "@material-ui/core";
import { ToggleButtonGroup } from "@material-ui/lab";

const StyledToggleButtonGroup = withStyles((theme) => ({
	grouped: {
		marginBottom: 20,
		margin: theme.spacing(0.5),
		borderRadius: 0,
		border: "1px solid #000000",
		fontSize: 12,
		color: "#333333",
		"&:not(:first-child)": {
			borderLeft: "1px solid #000000",
		},
		".MuiToggleButton-root.Mui-selected": {
			color: "#fff",
		},
	},
}))(ToggleButtonGroup);

export default StyledToggleButtonGroup;
