const { get } = require('lodash');
const { CONNECT_URLS, PROGRAM_ID } = window.config;
const transactionsBaseUrl = `${CONNECT_URLS.transactions}/${PROGRAM_ID}`;
const programBaseUrl = `${CONNECT_URLS.programs}/${PROGRAM_ID}`;

export default {
  getTransactionFields: async (payload) => {
    const programConfig = await window.apiClient.get(
      `${programBaseUrl}/config`
    );
    return get(
      programConfig,
      'transactions.purchase_transaction.transaction_field_configuration',
      []
    );
  },
  verifyTransactionCode: async (payload) => {
    return window.apiClient.post(
      `${transactionsBaseUrl}/purchase/verify`,
      payload
    );
  },
  getTransactionHistory: async () => {
    return window.apiClient.get(`${transactionsBaseUrl}`, {
      page: 1,
      include_voided: true,
    });
  },
  postTransaction: async (payload) => {
    return window.apiClient.post(`${transactionsBaseUrl}/purchase`, payload);
  },
  putTransaction: async (payload) => {
    return window.apiClient.post(
      `${transactionsBaseUrl}/uploadreceipt`,
      payload
    );
  },
  voidTransaction: async (payload) => {
    return window.apiClient.del(`${transactionsBaseUrl}`, payload);
  },
};
