import { IonContent, IonPage } from '@ionic/react';
import { createStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import Header from '../common/Header';
import UploadReceiptForm from '../components/Forms/UploadReceipt.form';

const styles = ({ spacing, palette }) =>
  createStyles({
    root: {
      margin: spacing(2),
      padding: spacing(2),
    },
    header: {
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      marginBottom: '16px',
    },
    content: {
      padding: 16,
    },
  });

const UploadReceiptPage = ({ classes = {}, programStore, history }) => {
  const { translate } = programStore;

  return (
    <IonPage>
      <Header
        title={translate['UPLOAD_RECEIPT']}
        backButton={true}
        backButtonFunction={() => {
          history.replace('/upload-reciept-list');
        }}
        menuButton={false}
      />
      <IonContent>
        <div className={classes.content}>
          {<UploadReceiptForm></UploadReceiptForm>}
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => ({
  programStore: state.programStore,
  productStore: state.productStore,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(UploadReceiptPage));
