import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "./components/Loader/Loader.component";
import Toast from "./components/Toast/Toast.component";
import Dialog from "./components/Dialog/Dialog.component";
import MainContainer from "./containers/MainContainer";
import appService from "./services/app.service";

class MasterPage extends Component {
  async componentDidMount() {
    await this.props.getUserFields();
    await this.props.getCustomerFields();
    await this.props.getTransactionFields();
    await this.props.getTranslation();

    await appService.checkApplicationUpdate();
  }

  render() {
    const { isLoading } = this.props.uiStore;

    return (
      <React.Fragment>
        <MainContainer />
        <Toast />
        <Dialog />
        {isLoading && <Loader />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  uiStore: state.uiStore,
  authStore: state.authStore,
});

const mapDispatchToProps = ({
  authStore,
  userStore,
  customerStore,
  transactionStore,
  programStore,
}) => ({
  ...authStore,
  ...userStore,
  ...customerStore,
  ...programStore,
  ...transactionStore,
});

export default connect(mapStateToProps, mapDispatchToProps)(MasterPage);
