import { Requestor } from "@openid/appauth";
import axios from 'axios';
export class AxiosRequestor extends Requestor {
    async xhr(settings) {
        let instance = axios.create({
            timeout: 8000,
            headers: settings.headers
        });
        if (!settings.method)
            settings.method = "GET";
        switch (settings.method) {
            case "GET":
                return instance.get(settings.url).then((value) => value.data);
            case "POST":
                return instance.post(settings.url, settings.data).then((value) => value.data);
            case "PUT":
                return instance.put(settings.url, settings.data).then((value) => value.data);
            case "DELETE":
                return instance.delete(settings.url).then((value) => value.data);
        }
    }
}