import 'capacitor-secure-storage-plugin';
import { take, map, skipWhile } from 'rxjs/operators';
import { isPlatform } from '@ionic/react';
import { AppVersion } from "@ionic-native/app-version";
import { Plugins } from '@capacitor/core';
import { IonicAuth, DefaultBrowser, AuthActions } from 'ionic-appauth';
import { CapacitorBrowser, CapacitorStorage } from 'ionic-appauth/lib/capacitor';
import { CordovaSecureStorage } from 'ionic-appauth/lib/cordova';
import { AxiosRequestor } from './AxiosService';
import { isNull } from 'lodash';

const { App, Device, SecureStoragePlugin } = Plugins;

const storage = {
    async getItem(key) {
      return SecureStoragePlugin.get({ key })
    },
    async setItem(key, value) {
      return SecureStoragePlugin.set({ key, value })
    },
    async removeItem(key) {
      return SecureStoragePlugin.remove({ key });
    }
  }
  export const TOKEN_KEY = {
    ACCESS_TOKEN: `mobile-app-${process.env.REACT_APP_PACKAGE_NAME}-access-token`,
    REFRESH_TOKEN: `mobile-app-${process.env.REACT_APP_PACKAGE_NAME}-refresh-token`,
    FROM_REGISTER: 'from-register', // to mark if user already 'logged in' while registration
  };

export class AuthService extends IonicAuth {
    static accessToken;
    static refreshToken;
    constructor(requestor = new AxiosRequestor(), browser = (isPlatform('capacitor')) ? new CapacitorBrowser() : new DefaultBrowser(), storage = (isPlatform('capacitor')) ? new CapacitorStorage() : new CapacitorStorage()) {
        super(browser, storage, requestor);

        console.log(`is capacitor: ${isPlatform('capacitor')}`)

        this.initialized = false
    }

    async initialize() {
      let packageName = null;
      const { operatingSystem, platform } = await Device.getInfo();
        if (["android", "ios"].includes(operatingSystem) && platform !== "web") {
          packageName = await AppVersion.getPackageName();
        }

        const { protocol, hostname, port } = window.location;
        const host = packageName
          ? `${packageName}:/`
          : `${protocol}//${hostname}${port && ":" + port}`;

        this.authConfig = {
            identity_client: window.config.KEYCLOAK_CONFIG.client_id,
            identity_server: `${window.config.KEYCLOAK_CONFIG.auth_server_url}/realms/${window.config.KEYCLOAK_CONFIG.realm}`,
            // redirect_url: `${host}/login-redirect`,
            // end_session_redirect_url: `${host}/end-redirect`,
            redirect_url: isPlatform('capacitor') ? host + '/login-redirect' : host + '/login-redirect',
            end_session_redirect_url: isPlatform('capacitor') ?  host + '/end-redirect' : host + '/end-redirect',
            scopes: 'openid offline_access',
            usePkce: true
        };
        this.initialized = true
    }

    async startUpAsync() {
        if (isPlatform('capacitor')) {
            App.addListener('appUrlOpen', (data) => {
                if (data.url !== undefined) {
                    this.handleCallback(data.url);
                }
            });
        }
        return super.startUpAsync();
    }
    handleCallback(callbackUrl) {
        if (this.authConfig) {
            if ((callbackUrl).indexOf(this.authConfig.redirect_url) === 0) {
                this.AuthorizationCallBack(callbackUrl);
            }
            if ((callbackUrl).indexOf(this.authConfig.end_session_redirect_url) === 0) {
                this.EndSessionCallBack();
            }
        }
    }
    static get Instance() {
        if (!this.authService) {
            this.authService = new AuthService();
        }
        return this.authService;
    }
    isAuthenticated() {
        return this.authObservable.pipe(skipWhile((action) => action.action === AuthActions.Default), take(1), map((action) => action.tokenResponse !== undefined)).toPromise();
    }

    static get isAuthenticated() {
      return !isNull(this.accessToken)
    }
  
    static async storeAccessToken(token) {
      await storage.setItem(TOKEN_KEY.ACCESS_TOKEN, token).catch(e => {});;
      await storage.getItem(TOKEN_KEY.ACCESS_TOKEN).then(res => AuthService.accessToken = res.value)
    }

    static async getAccessToken() {
      await storage.getItem(TOKEN_KEY.ACCESS_TOKEN).then(res => AuthService.accessToken = res.value)
    }
  
    static async storeRefreshToken(token) {
      await storage.setItem(TOKEN_KEY.REFRESH_TOKEN, token).catch(e => {});
      await storage.getItem(TOKEN_KEY.REFRESH_TOKEN).then(res => AuthService.refreshToken = res.value)
    }

    static async getRefreshToken() {
      await storage.getItem(TOKEN_KEY.REFRESH_TOKEN).then(res => AuthService.refreshToken = res.value)
    }
  
    static clearStorage() {
      storage.removeItem(TOKEN_KEY.ACCESS_TOKEN);
      storage.removeItem(TOKEN_KEY.REFRESH_TOKEN);
      storage.removeItem(TOKEN_KEY.FROM_REGISTER);
    }

    static async setFromRegister(value) {
        await storage.setItem(TOKEN_KEY.FROM_REGISTER, value).catch(e => {});
    }

    static async getFromRegister() {
        const fromRegister = await storage.getItem(TOKEN_KEY.FROM_REGISTER);
        // check if storage have value with key FROM_REGISTER
        // somehow for this version, if no key found, will return weird value instead
        return fromRegister.value === 'ée' ? undefined : fromRegister.value;
    }
}