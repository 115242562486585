import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonRouterOutlet, IonSplitPane, IonMenu } from '@ionic/react';
import { Route, Redirect } from 'react-router-dom';

import HomePage from './HomePage';

import EndRedirect from '../common/EndRedirect';
import LoginRedirect from '../common/LoginRedirect';

import LoginPage from './LoginPage';
import VerificationPage from './VerificationPage';
import TransactionHistoryPage from './TransactionHistoryPage';
import RegisterPatientPage from './RegisterPatientPage';
import RedeemDiscountPage from './RedeemDiscountPage';
import UploadReceiptPage from './UploadReceiptPage';
import PatientCodePage from './PatientCodePage';
import UploadReceiptListPage from './UploadReceiptListPage'

import SettingsPage from './SettingsPage';
import AboutPage from './AboutPage';
import FAQPage from './FAQPage';
import TermsAndConditionsPage from './TermsAndConditionsPage';

import SidebarMenu from '../components/Common/SidebarMenu.component';
import PrivateRoute from '../common/PrivateRoute';
import PublicRoute from '../common/PublicRoute';

class MainContainer extends Component {
  render() {
    const { isPrivateRoute } = this.props.uiStore;
    return (
      <IonSplitPane contentId="main" when="xl">
        {isPrivateRoute && (
          <IonMenu contentId="main" swipeGesture="false">
            <SidebarMenu />
          </IonMenu>
        )}

        <IonRouterOutlet id="main">
          <PrivateRoute path="/home" component={HomePage} exact />
          <PrivateRoute path="/about" component={AboutPage} exact />
          <PrivateRoute path="/user-guide" component={FAQPage} exact />
          <PrivateRoute
            path="/terms-and-conditions"
            component={TermsAndConditionsPage}
            exact
          />
          <PrivateRoute path="/patientcode" component={PatientCodePage} exact />
          <PrivateRoute
            path="/register"
            component={RegisterPatientPage}
            exact
          />
          <PrivateRoute
            path="/transaction-history"
            component={TransactionHistoryPage}
            exact
          />
          <PrivateRoute
            path="/upload-reciept-list"
            component={UploadReceiptListPage}
            exact
          />
          <PrivateRoute
            path="/redeem/:code"
            component={RedeemDiscountPage}
            exact
          />
          <PrivateRoute
            path="/upload-receipt/:transactionId"
            component={UploadReceiptPage}
            exact
          />
          <PrivateRoute path="/settings" component={SettingsPage} exact />

          <PublicRoute path="/verify" component={VerificationPage} exact />

          <PublicRoute path="/end-redirect" component={EndRedirect} exact />
          <PublicRoute path="/login-redirect" component={LoginRedirect} exact />
          <PublicRoute path="/login" component={LoginPage} exact />
          <PublicRoute path="/verify" component={VerificationPage} exact />
          <PublicRoute path="/terms" component={TermsAndConditionsPage} exact />

          <Route path="/" render={() => <Redirect to="/home" />} exact />
        </IonRouterOutlet>
      </IonSplitPane>
    );
  }
}

const mapStateToProps = (state) => ({
  authStore: state.authStore,
  userStore: state.userStore,
  uiStore: state.uiStore,
});

export default connect(mapStateToProps)(MainContainer);
