import React, { Component } from "react";

import {
  Grid,
  TextField as TextInput,
  Typography,
  createStyles,
  withStyles,
  Select,
  MenuItem,
} from "@material-ui/core";
import { ErrorMessage } from "formik";

import InputBase from "@material-ui/core/InputBase";

const BootstrapInput = withStyles((theme) => ({
  root: {
    width: "100%",
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: "relative",
    border: "1px solid #ced4da",
    borderRight: "none",
    fontSize: 16,
    padding: 18,
    minHeight: "inherit",
    height: 18,
    // "&:focus": {
    //   borderColor: "#80bdff",
    //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    // },
  },
}))(InputBase);

const styles = ({ spacing, palette }) =>
  createStyles({
    formControl: {
      width: "100%",
      fontSize: 14,
    },
    helperText: {
      marginTop: 5,
      marginLeft: 5,
    },
    outlinedInput: {
      borderRadius: 0,
    },
    textInput: {
      borderRadius: 0,
    },
    select: {
      width: "100%",
      borderColor: palette.secondary.main,
    },
    selectContainer: {
      display: "flex",
      // border: "1px solid",
      // borderColor: palette.secondary.main,
    },
    fieldContainer: {
      margin: "16px 0px",
    },
    notchedOutline: {
      borderColor: palette.secondary.main,
    },
    label: {
      color: palette.secondary.main,
    },
  });

class MobileNumberField extends Component {
  state = {
    countryCode: "+62",
    phoneNumber:
      (this.props.field.value && this.props.field.value.substring(3)) || "",
  };

  render() {
    const {
      field,
      type,
      placeholder,
      label,
      disabled,
      helperText,
      upperCase,
      required,
      classes,
      setFieldValue,
      mobilePrefixes,
    } = this.props;

    let { characterLimit = 12 } = this.props;
    if (!characterLimit) characterLimit = 12;

    const onChangeDropdown = (data) => {
      this.setState({ ...this.state, countryCode: data.target.value });
      setFieldValue(field.name, data.target.value + this.state.phoneNumber);
    };

    const onChangeField = (data) => {
      this.setState({ ...this.state, phoneNumber: data.target.value });
      setFieldValue(
        field.name,
        this.state.countryCode +
          data.target.value.replace(/\D/g, "").substring(0, characterLimit)
      );
    };

    return (
      <Grid
        container
        key={field.name}
        spacing={0}
        className={classes.fieldContainer}
      >
        <Grid item sm={3} xs={3} className={classes.selectContainer}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id={field.name + "_dropdown"}
            value={(field.value && field.value.slice(0, 3)) || "+62"}
            onChange={onChangeDropdown}
            input={<BootstrapInput />}
            classes={{
              root: classes.select,
            }}
          >
            {mobilePrefixes &&
              mobilePrefixes.map((prefix) => {
                return (
                  <MenuItem value={prefix.calling_code}>
                    {prefix.calling_code}
                  </MenuItem>
                );
              })}
          </Select>
        </Grid>
        <Grid item sm={9} xs={9}>
          <TextInput
            type="text"
            label={
              label ? `${label}${required ? " *" : "(opsional)"}` : placeholder
            }
            value={(field.value && field.value.substring(3)) || ""}
            onChange={onChangeField}
            onBlur={field.onBlur}
            name={field.name}
            disabled={disabled}
            variant="outlined"
            color="secondary"
            onInput={
              upperCase
                ? (e) => {
                    const start = e.target.selectionStart;
                    const end = e.target.selectionEnd;
                    e.target.value = e.target.value.toUpperCase();
                    e.target.setSelectionRange(start, end);
                  }
                : null
            }
            InputProps={{
              classes: { notchedOutline: classes.notchedOutline },
              autoComplete: "off",
            }}
            InputLabelProps={{
              className: classes.label,
            }}
            fullWidth
          />
        </Grid>
        {helperText && (
          <>
            <Typography variant="caption" display="block" gutterBottom color="">
              {helperText}
            </Typography>
            <br />
          </>
        )}
        <ErrorMessage
          name={field.name}
          render={(message) => {
            return (
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                color="error"
              >
                {message}
              </Typography>
            );
          }}
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(MobileNumberField);
