import React from "react";
import { connect } from "react-redux";
import { Plugins } from "@capacitor/core";
import { useHistory } from "react-router-dom";
import { IonItem } from "@ionic/react";
import {
  List,
  Box,
  Link,
  Typography,
  createStyles,
  withStyles,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Button,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

// IMG
import LogoImg from "./../../img/logo.png";
import SupportIcon from "./../../img/icons/support.png";
import AboutIcon from "./../../img/icons/about.png";
import HomeIcon from "./../../img/icons/home.png";
import TnCIcon from "./../../img/icons/tnc.png";
import UserGuideIcon from "./../../img/icons/user-guide.png";
import ExitIcon from "./../../img/icons/exit.png";

import LinkItem from "./LinkItem.component";

const styles = ({ spacing, palette }) =>
  createStyles({
    root: {
      minHeight: "100vh",
      color: "white",
      background: "linear-gradient(#0460A9, #014A90)",
      fallbacks: {
        background: "#0460A9",
      },
    },
    icon: {
      color: grey[700],
    },
    logo: {
      padding: spacing(4),
    },
    "@media (max-width: 767.98px)": {
      logo: {
        paddingTop: spacing(3.5),
        paddingRight: spacing(4),
        paddingBottom: spacing(3.5),
        paddingLeft: spacing(4),
      },
    },
    linkHeader: {
      color: "white",
    },
    links: {
      color: "white",
      textDecoration: "underline",
      fontSize: 14,
    },
    linkDescription: {
      "& button": {
        height: 30,
      },
    },
    logoutHeader: {
      textAlign: "center",
    },
    logoutButton: {
      textTransform: "capitalize",
    },
    dialogText: {
      color: "#000",
    },
  });

const { Browser } = Plugins;

const SidebarMenu = ({ classes = {}, logoutUser, programStore }) => {
  const [open, setOpen] = React.useState(false);
  const { translate } = programStore;
  let history = useHistory();
  let pathname = history.location.pathname;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Box className={`${classes.root} no-print`}>
        <Box>
          <img className={classes.logo} src={LogoImg} alt="Stellar logo" />
        </Box>
        <List component="nav">
          <LinkItem
            to="/home"
            label="Home"
            icon={<img src={HomeIcon} alt="Home" />}
            selected={pathname === "/home"}
          />
          <LinkItem
            to="/user-guide"
            label={translate["USER_GUIDE"]}
            icon={<img src={UserGuideIcon} alt={translate["USER_GUIDE"]} />}
            selected={pathname === "/user-guide"}
          />
          <LinkItem
            to="/about"
            label={translate["ABOUT"]}
            icon={<img src={AboutIcon} alt={translate["ABOUT"]} />}
            selected={pathname === "/about"}
          />
          <LinkItem
            to="/terms-and-conditions"
            label={translate["TERMS_AND_CONDITIONS"]}
            icon={<img src={TnCIcon} />}
            selected={pathname === "/terms-and-conditions"}
          />
          <LinkItem
            onClick={handleClickOpen}
            label={translate["LOGOUT"]}
            icon={<img src={ExitIcon} alt={translate["LOGOUT"]} />}
          />
        </List>

        {/* <IonItem className="sidebarmenu-linkitem" lines="none">
          <ListItemIcon className={classes.icon}>
            <img src={SupportIcon} alt={translate["CONTACT_US_FOR_SUPPORT"]} />
          </ListItemIcon>
          <ListItemText
            className={classes.label}
            primary={
              <Typography className={classes.linkHeader}>
                {translate["CONTACT_US_FOR_SUPPORT"]}
              </Typography>
            }
          />
        </IonItem>

        <Link
          href="mailto:stellar.support@psplink.com"
          className={classes.linkDescription}
        >
          <LinkItem
            //onClick={() => getHTML("terms_and_conditions")}
            label={
              <>
                <Typography variant="caption">
                  {translate["EMAIL_ADDRESS"]}:
                </Typography>
                <Typography>
                  <Link
                    href="mailto:stellar.support@psplink.com"
                    className={classes.links}
                  >
                    stellar.support@psplink.com
                  </Link>
                </Typography>
              </>
            }
          />
        </Link>

        <Link href="tel:+6285574670028" className={classes.linkDescription}>
          <LinkItem
            //onClick={() => getHTML("terms_and_conditions")}
            label={
              <>
                <Typography variant="caption">
                  {translate["MOBILE_NUMBER"]}:
                </Typography>
                <Typography>
                  <Link href="tel:+6285574670048" className={classes.links}>
                    +6285574670028
                  </Link>
                </Typography>
              </>
            }
          />
        </Link> */}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.logoutHeader} id="alert-dialog-title">
          <b>Stellar</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={classes.dialogText}
            id="alert-dialog-description"
          >
            {translate["ARE_YOU_SURE_YOU_WANT_TO_LOGOUT"]}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.logoutButton}
          >
            {translate["NO"]}
          </Button>
          <Button
            onClick={logoutUser}
            color="primary"
            variant="contained"
            className={classes.logoutButton}
            autoFocus
          >
            {translate["YES"]}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  programStore: state.programStore,
});

const mapDispatchToProps = ({ authStore }) => ({
  ...authStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SidebarMenu));
