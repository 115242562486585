import { IconButton, Typography } from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import { isEmpty } from "lodash";
import React from "react";
import StyledToggleButtonGroup from "../../FormFields/StyledToggleButtonGroup";

const ProductSelection = ({
	translate,
	handleSkuSelection,
	productNames,
	classes,
	drug,
	productList,
	subType,
	dosage,
	boxes,
	handleBoxes,
	specializeType,
	isTabletSplitting,
}) => {
	/**
	 * Transform rendered sub type text or label.
	 * 
	 * @param {String} name Sub-type name.
	 * @returns {String}
	 */
	const transformSubTypeLabel = (name) => {
		const lowered = name.toLowerCase();
		switch (lowered) {
			case 'femara':
				return "Kryxana + Femara";
			case 'non-femara':
				return "Kryxana Only";
			case 'jkn':
				return "Pasca JKN";
			case 'non-jkn coming soon':
			case 'non-jkn':
				return 'Non JKN'
		
			default:
				return name;
		}
	}

	/**
	 * Get maximum allowed purchase based on selected SKU.
	 * 
	 * @returns {Number}
	 */
	const getMaximumAllowedPurchase = () => {
		let skuObject = null;
		if ((drug && drug.brand_name) && (productList && productList[drug.brand_name].length > 0)) {
			skuObject = productList[drug.brand_name].find((item) => item.product_sku_id === dosage);
		}

		return (skuObject && skuObject.maximum_purchase_allowed) || 0;
	}

	return (
		<>
			<div className={classes.inputLabel}>{translate["SELECT_DRUG"]}</div>
			<StyledToggleButtonGroup
				size="small"
				value={drug}
				exclusive
				onChange={(e, v) => handleSkuSelection(e, v, "drug")}
				aria-label="text drug"
				className={classes.toggleButton}
			>
				{productNames.map((p) => (
					<ToggleButton
						key={p?.brand_name}
						value={p}
						className={classes.toggleButton}
					>
						{`${p?.brand_name}`}
					</ToggleButton>
				))}
			</StyledToggleButtonGroup>
			{!isEmpty(drug) &&
				"subtype" in
					productList[drug.brand_name][0].product_sku.sku_metadata && (
					<>
						<div className={classes.inputLabel}>
							{drug.brand_name.includes("kryxana")
								? translate["SELECT_SUB_TYPE"]
								: translate["SELECT_SUB_TYPE_2"]}
						</div>
						<StyledToggleButtonGroup
							size="small"
							value={subType}
							exclusive
							onChange={(e, v) => handleSkuSelection(e, v, "subtype")}
							aria-label="text dosage"
						>
							{productList[
								drug.brand_name
							][0].product_sku.sku_metadata.subtype.map((p) => (
								<ToggleButton
									key={p}
									value={p}
									disabled={p.toLowerCase().includes("coming soon")}
									className={classes.toggleButton}
								>
									{`${transformSubTypeLabel(p)}`}
								</ToggleButton>
							))}
						</StyledToggleButtonGroup>
					</>
				)}

			{!isEmpty(drug) && (
				<>
					<div className={classes.inputLabel}>{translate["SELECT_DOSAGE"]}</div>
					<StyledToggleButtonGroup
						size="small"
						value={dosage}
						exclusive
						onChange={(e, v) => handleSkuSelection(e, v, "dosage")}
						aria-label="text dosage"
					>
						{productList[drug.brand_name].map((p) => (
							<ToggleButton
								key={p.product_sku_id}
								value={p.product_sku_id}
								className={classes.toggleButton}
								style={{ textTransform: "lowercase" }}
							>
								{`${p.product_sku.dosage_quantity} ${p.product_sku.dosage_unit}`}
							</ToggleButton>
						))}
					</StyledToggleButtonGroup>
				</>
			)}

			{!isEmpty(dosage) && (
				<>
					<div className={classes.inputLabel}>
						{translate["QUANTITY_PER_BOX"]}
					</div>
					<div className={classes.counterContainer}>
						<input
							type="text"
							value={boxes}
							className={classes.counterInput}
						></input>
						<IconButton
							aria-label="plus"
							className={classes.plus}
							onClick={() => {
								handleBoxes(1);
							}}
						>
							<AddIcon />
						</IconButton>
						<IconButton
							aria-label="minus"
							className={classes.minus}
							onClick={() => {
								handleBoxes(-1);
							}}
						>
							<RemoveIcon />
						</IconButton>
					</div>
					{drug && drug.maximum_purchase_allowed && (<div className={classes.counterLabel}>
						{translate["MAXIMUM_OF_FOUR_BOXES_ALLOWED"].replace('4 box', getMaximumAllowedPurchase())}
					</div>)}

					{drug && drug.brand_name === "uperio" && (
						<>
						<Typography className={classes.header2} variant="h5">
							{translate["SELECT_SPECIALIZED_TYPE"]}
							<span className={classes.spanLabel}>
								({translate["FOLLOW_PRESCRIPTION"]})
							</span>
						</Typography>
						<StyledToggleButtonGroup
							size="small"
							value={specializeType}
							exclusive
							onChange={(e, v) => handleSkuSelection(e, v, "specialize-type")}
							aria-label="text dosage"
						>
							<ToggleButton
								key="heart_medicine"
								value="heart_medicine"
								className={classes.toggleButton}
							>
								{translate["HEART_MEDICINE"]}
							</ToggleButton>
							<ToggleButton
								key="internal_medicine"
								value="internal_medicine"
								className={classes.toggleButton}
							>
								{translate["INTERNAL_MEDICINE"]}
							</ToggleButton>
						</StyledToggleButtonGroup>
						<Typography className={classes.header2} variant="h5">
							{translate["SELECT_TABLE_SPLITTING"]}
							<span className={classes.spanLabel}>
								({translate["FOLLOW_PRESCRIPTION"]})
							</span>
						</Typography>
						<StyledToggleButtonGroup
							size="small"
							value={isTabletSplitting}
							exclusive
							onChange={(e, v) => handleSkuSelection(e, v, "tablet-split")}
							aria-label="text dosage"
						>
							<ToggleButton
								key="split"
								value="yes"
								className={classes.toggleButton}
							>
								{translate["YES"]}
							</ToggleButton>
							<ToggleButton
								key="no-split"
								value="no"
								className={classes.toggleButton}
							>
								{translate["NO"]}
							</ToggleButton>
						</StyledToggleButtonGroup>
						</>
					)}
					
				</>
			)}
		</>
	);
};

export default ProductSelection;
