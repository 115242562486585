const { CONNECT_URLS, PROGRAM_ID } = window.config;
const customersBaseUrl = `${CONNECT_URLS.customers}/${PROGRAM_ID}`;
const programBaseUrl = `${CONNECT_URLS.programs}/${PROGRAM_ID}`;

export default {
  getCustomerFields: async (payload) => {
    const programConfig = await window.apiClient.get(
      `${programBaseUrl}/config`
    );

    return programConfig
      ? programConfig.customers.customer_field_configuration
      : [];
  },
  registerPatient: async (payload) => {
    return window.apiClient.post(`${customersBaseUrl}`, payload);
  },
};
