const { CONNECT_URLS, PROGRAM_ID } = window.config;
const storageBaseUrl = `${CONNECT_URLS.storage}/${PROGRAM_ID}`;

export default {
  uploadPhoto: async (payload) => {
    return window.apiClient.upload(
      `${storageBaseUrl}/anonymous-upload`,
      payload
    );
  },
};
