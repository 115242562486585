import React, { Component } from "react";

import {
  Button as MUIButton,
  createStyles,
  withStyles,
} from "@material-ui/core";

const styles = ({ spacing }) =>
  createStyles({
    button: {
      border: "2px solid #000000",
      borderRadius: 0,
      marginBottom: spacing(4),
      fontWeight: 700,
      letterSpacing: "normal",
      textTransform: "none",
    },
  });

class Button extends Component {
  render() {
    const { classes, children } = this.props;

    return (
      <MUIButton
        variant="outlined"
        color="secondary"
        className={classes.button}
        {...this.props}
      >
        {children}
      </MUIButton>
    );
  }
}

export default withStyles(styles)(Button);
