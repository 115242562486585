import React, { Component } from "react";

import {
  Grid,
  Typography,
  Button,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { ErrorMessage } from "formik";

const styles = ({ spacing }) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    button: {
      textTransform: "capitalize",
      border: "2px solid #000000",
      borderRadius: 0,
      fontWeight: 700,
    },
  });

class FileUploadField extends Component {
  state = {
    showPassword: false,
  };

  render() {
    const { field, label, classes, setFieldValue } = this.props;

    return (
      <Grid container spacing={4} key={field.name}>
        <Grid item sm={12} xs={12}>
          <input
            accept="image/*"
            className={classes.input}
            style={{ display: "none" }}
            id={field.name}
            onChange={(event) => {
              setFieldValue(field.name, event.currentTarget.files[0]);
            }}
            onBlur={field.onBlur}
            type="file"
          />
          <label htmlFor={field.name}>
            <Button
              color="secondary"
              component="span"
              variant="outlined"
              className={classes.button}
              disableElevation
            >
              {label}
            </Button>
          </label>
          {field.value && field.value.name}

          <ErrorMessage
            name={field.name}
            render={(message) => {
              return (
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  color="error"
                >
                  {message}
                </Typography>
              );
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(FileUploadField);
