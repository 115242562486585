import { AuthService } from "../services/AuthService";

export default {
  state: {
    loggedUser: null,
    isLoggedIn: false,
    authChecked: false,
    isLoading: true,
  },
  reducers: {
    setLoggedUser(state, payload) {
      return {
        ...state,
        loggedUser: payload,
        isLoggedIn: !!payload,
        authChecked: true,
      };
    },
    setIsLoading(state, payload) {
      return {
        ...state,
        isLoading: payload,
      };
    },
  },
  effects: (dispatch) => ({
    checkUser: async () => {
      dispatch.authStore.setIsLoading(true);
      let isAuth;
      let loggedInUser = null;
      let fromRegister
      try {
        fromRegister = await AuthService.getFromRegister();
      } catch (e) {
        console.log(e)
      }
      if (fromRegister && fromRegister === 'true') {
        isAuth = true;
        loggedInUser = await window.apiClient.setLoggedInUserFromRegister();
      } else {
        isAuth = await AuthService.Instance.isAuthenticated();
        if (isAuth) {
          const tokens = await window.AuthService.getTokenFromObserver();
          loggedInUser = await window.apiClient.setLoggedInUser(tokens);
        }
      }
      console.log(`Got isAuth ${isAuth}`);
      console.log("Got loggedInUser", loggedInUser);
      // const user = await AuthService.Instance.getUserInfo();
      // console.log(user);
      // await window.apiClient.setLoggedInUser(user);
      // return dispatch.authStore.setLoggedUser(user);

      if (isAuth) {
        dispatch.userStore.setIsLoadingProfile(true);
      }

      dispatch.authStore.setLoggedUser(loggedInUser);
      dispatch.authStore.setIsLoading(false);
      return loggedInUser;
    },
    loginUser: () => AuthService.Instance.signIn(),
    logoutUser: async () => {
      try {
        AuthService.clearStorage();
        await AuthService.Instance.storage.clear();
        await AuthService.Instance.signOut();
      } catch (e) {
        console.error("Cannot logout via AuthService");
        console.error(e);
      }
      window.location.reload();
    },
  }),
};
