import React from "react";
import { useLocation } from "react-router-dom";
import { IonMenuToggle } from "@ionic/react";
import {
  ListItemIcon,
  ListItemText,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { IonItem } from "@ionic/react";

const styles = ({ spacing }) =>
  createStyles({
    icon: {
      color: "white",
    },
    label: {
      color: "white",
    },
  });

const LinkItem = ({
  to = "",
  label = "",
  classes = {},
  onClick,
  icon,
  isMobileView,
  button = true,
  selected = false,
}) => {
  const location = useLocation();

  if (location.pathname.indexOf(to) >= 0) {
    to = null;
  }
  return (
    <IonMenuToggle autoHide={isMobileView}>
      {to && (
        <IonItem
          detail={false}
          button
          className="sidebarmenu-linkitem"
          routerLink={to}
          onClick={onClick}
          routerDirection="back"
          lines="none"
          style={selected ? { backgroundColor: "#4388bf" } : {}}
        >
          <ListItemIcon className={classes.icon}>{icon && icon}</ListItemIcon>
          <ListItemText className={classes.label} primary={label} />
        </IonItem>
      )}
      {!to && (
        <IonItem
          detail={false}
          button
          className="sidebarmenu-linkitem"
          onClick={onClick}
          routerDirection="back"
          lines="none"
          style={selected ? { backgroundColor: "#4388bf" } : {}}
        >
          <ListItemIcon className={classes.icon}>{icon && icon}</ListItemIcon>
          <ListItemText className={classes.label} primary={label} />
        </IonItem>
      )}
    </IonMenuToggle>
  );
};

export default withStyles(styles)(LinkItem);
