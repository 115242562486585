import { customerService } from "../services/index.service";
import stores from "./index.model";
import generateErrorMessage from "../utilities/errorMessage.utility";

export default {
  state: {
    customerFields: [],
  },

  reducers: {
    setCustomerFields(state, payload) {
      return {
        ...state,
        customerFields: payload,
      };
    },
  },

  effects: (dispatch) => ({
    getCustomerFields: async () => {
      try {
        const customerFields = await customerService.getCustomerFields();

        dispatch.customerStore.setCustomerFields(customerFields);
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },
    registerPatient: async (payload) => {
      try {
        stores.dispatch.uiStore.showLoading(true);
        payload.chosen_language = "my";

        const { message } = await customerService.registerPatient(payload);

        stores.dispatch.uiStore.showLoading(false);
        stores.dispatch.uiStore.showDialog({
          message: window.translate["PATIENT_IS_NOW_REGISTERED"],
        });
      } catch (err) {
        console.log(err);
        stores.dispatch.uiStore.showLoading(false);
        stores.dispatch.uiStore.showDialog({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },
  }),
};
