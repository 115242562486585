import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { IonApp } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import { ThemeProvider } from "@material-ui/core/styles";

import stores from "./models/index.model";
import MasterPage from "./MasterPage";
import theme from "./theme/theme.js";

import { AuthActions, AuthActionBuilder } from "ionic-appauth";
import { Plugins } from "@capacitor/core";

import { AuthService } from "./services/AuthService";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

const { Network } = Plugins;

const MainPage = () => {
  const [authComplete, setAuthComplete] = useState(false);

  if (!AuthService.Instance.initialized) {
    window.AuthService = AuthService.Instance;
    AuthService.Instance.initialize().then(() => {
      AuthService.Instance.startUpAsync().then(() => {
        setAuthComplete(true);
      });
    });
  }

  const [action, setAction] = useState(AuthActionBuilder.Default);

  useEffect(async () => {
    const sub = AuthService.Instance.authObservable.subscribe((action) => {
      setAction(action);
      if (action.action === AuthActions.SignInSuccess) {
        console.log("Sign In Success");
        window.location.replace("/home");
      }
    });
    window.config.NETWORK_CONNECTED = (await Network.getStatus())?.connected || true;

    return function cleanup() {
      sub.unsubscribe();
    };
  }, []);

  return (
    <IonApp>
      <Provider store={stores}>
        {authComplete && (
          <IonReactRouter animated="false">
            <ThemeProvider theme={theme}>
              <MasterPage />
            </ThemeProvider>
          </IonReactRouter>
        )}
      </Provider>
    </IonApp>
  );
};

export default MainPage;
