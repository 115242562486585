import { transactionService } from '../services/index.service';
import generateErrorMessage from '../utilities/errorMessage.utility';
import stores from './index.model';

export default {
  state: {
    transactionFields: [],
    transactionList: [],
    created_at: '',
  },
  reducers: {
    setTransactionList(state, payload) {
      return {
        ...state,
        transactionList: payload,
      };
    },
    setTransactionFields(state, payload) {
      return {
        ...state,
        transactionFields: payload,
      };
    },
    setCreatedAt(state, payload) {
      return {
        ...state,
        created_at: payload,
      };
    },
  },

  effects: (dispatch) => ({
    getTransactionFields: async () => {
      try {
        const transactionFields =
          await transactionService.getTransactionFields();

        dispatch.transactionStore.setTransactionFields(transactionFields);
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: 'warning',
        });
        throw err;
      }
    },
    changeCreatedAt: async (payload) => {
      dispatch.transactionStore.setCreatedAt(payload);
    },
    verifyTransactionCode: async (payload) => {
      try {
        const { products } = await transactionService.verifyTransactionCode(
          payload
        );
        stores.dispatch.productStore.consolidateState(products);
      } catch (err) {
        stores.dispatch.uiStore.showDialog({
          message: generateErrorMessage(err),
        });
        throw err;
      }
    },
    postTransaction: async (payload) => {
      try {
        const { result } = await transactionService.postTransaction(payload);
        const productSkuId = payload.products[0].product_sku_id;
        const drugName = productSkuId.toLowerCase().includes('uperio')
          ? 'gagal jantung'
          : productSkuId
              .replace(/^[a-z]/g, (c) => c.toUpperCase())
              .split('_')
              .splice(0, 1)
              .join(' ');
        stores.dispatch.uiStore.showDialog({
          message: `Pasien sekarang mendapatkan diskon Rp ${result.rebate_amount} untuk pembelian ${result.number_of_boxes} box obat ${drugName} dari program Stellar`,
        });

        return result;
      } catch (err) {
        console.log(err);
        stores.dispatch.uiStore.showDialog({
          message: generateErrorMessage(err),
        });
        // need to throw here to that it wont proceed to redirect
        throw err;
      }
    },

    putTransaction: async (payload) => {
      try {
        const { result } = await transactionService.putTransaction(payload);

        stores.dispatch.uiStore.showDialog({
          message: `Tanda Terima berhasil diunggah`,
        });

        return result;
      } catch (err) {
        console.log(err);
        stores.dispatch.uiStore.showDialog({
          message: generateErrorMessage(err),
        });
        // need to throw here to that it wont proceed to redirect
        throw err;
      }
    },
    getTransactionHistory: async () => {
      try {
        const { result } = await transactionService.getTransactionHistory();

        dispatch.transactionStore.setTransactionList(result);
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showLoading(false);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: 'warning',
        });
        throw err;
      }
    },
    voidTransaction: async (payload) => {
      try {
        await transactionService.voidTransaction(payload);
        stores.dispatch.uiStore.showDialog({
          message: window.translate['TRANSACTION_VOIDED'],
          actions: [
            {
              label: 'OK',
            },
          ],
        });
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: 'warning',
        });
      }
    },
  }),
};
