import {
  Box,
  createStyles, Grid,
  Typography,


  withStyles
} from "@material-ui/core";
import { ErrorMessage } from "formik";
import React, { Component } from "react";
import PinInput from "react-pin-input";


const styles = ({ spacing }) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    label: {
      marginTop: spacing(4),
      marginBottom: spacing(2),
      textAlign: "center",
    },
  });

class CodeInputField extends Component {
  componentDidMount() {
    this.props.form.setFieldValue(this.props.field.name, null);
  }

  handleChange = (value) => {
    this.props.form.setFieldValue(this.props.field.name, value);
  };

  render() {
    const { field, placeholder, label, disabled, classes } = this.props;

    return (
      <Grid container spacing={4} key={field.name}>
        <Grid item sm={12} xs={12}>
          <Box className={classes.label}>{label ? label : placeholder}</Box>

          <PinInput
            length={8}
            initialValue={field.value || ""}
            onChange={this.handleChange}
            onBlur={field.onBlur}
            disabled={disabled}
            type="alphanumeric"
            regexCriteria={/^[a-zA-Z]+$/}
            style={{
              display: "table",
              margin: "0 auto",
            }}
            inputStyle={{
              width: "30px",
              border: "none",
              borderBottom: "1px solid #4e73c8",
              backgroundColor: "#e5e8f0",
            }}
          />

          <ErrorMessage
            name={field.name}
            render={(message) => {
              return (
                <Typography
                  variant="caption"
                  display="block"
                  align="center"
                  gutterBottom
                  color="error"
                >
                  {message}
                </Typography>
              );
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(CodeInputField);
