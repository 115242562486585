import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/Loader/Loader.component";

const PrivateRoute = (props) => {
  let {
    component,
    authStore,
    userStore,
    checkUser,
    getUserProfile,
    uiStore,
    ...rest
  } = props;

  useEffect(() => {
    async function init() {
      const { isLoggedIn } = authStore;
      if (!isLoggedIn) {
        try {
          await checkUser();
          await getUserProfile();
        } catch (err) {
          console.log(err);
        }
      }
    }
    init();
    uiStore.setRouteIsPrivate(true);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        const { isLoggedIn, loggedUser, isLoading } = authStore;
        const { userProfile, isLoadingProfile } = userStore;

        if (isLoading || isLoadingProfile) {
          return <Loader />;
        } else {
          if (isLoggedIn) {
            if (loggedUser.email_verified || userProfile?.email_verified_at) {
              return React.createElement(component, props);
            } else {
              return <Redirect to="/verify" />;
            }
          } else {
            return <Redirect to="/login" />;
          }
        }
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  authStore: state.authStore,
  userStore: state.userStore,
});

const mapDispatchToProps = ({ userStore, authStore, uiStore }) => ({
  ...userStore,
  checkUser: authStore.checkUser,
  uiStore,
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
