import { createStyles, withStyles } from "@material-ui/core";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import FormikForm from "../../components/FormikForm/FormikForm.component";
import { HCP_CODE_LIST } from "../../utilities/constants";
import { generateValidationSchema } from "../../utilities/validationSchema.utility";

const styles = ({ spacing, palette }) =>
  createStyles({
    formikFields: {
      "& label": {
        color: palette.primary.dark,
      },
    },
  });

class UpdateProfile extends Component {
  state = {
    profileFields: [],
    validationSchema: {},
  };

  _setProfileFieldsFilter() {
    const hcpCode = this.props.userStore.userProfile.metadata.hcp_code;
    
    if(hcpCode === HCP_CODE_LIST.RG_SUP || hcpCode === HCP_CODE_LIST.RG_SOL) {
      return [
        "email",
        "mobile_number",
        "outlet_name",
        "outlet_name",
        "outlet_address",
        "distributor_account_number",
        "bank_name",
        "bank_account_name",
        "bank_account_number",
      ];
    }

    return [
      "email",
      "mobile_number",
      "outlet_name",
      "outlet_name",
      "outlet_address",
      "distributor_account_number"
    ];
  }

  async componentDidMount() {
    const { translate } = this.props.programStore;
    await this.props.getUserFields();
    const profileFields = _.filter(
      this.props.userStore.userFields,
      (field) => _.includes(this._setProfileFieldsFilter(), field.name.toLowerCase())
    );

    if (profileFields[0]) {
      profileFields[0].disabled = true;
      profileFields[0].label = translate["EMAIL_ADDRESS"];
      profileFields[0].placeholder = translate["EMAIL_ADDRESS"];
      profileFields[0].is_required = false;
    }
    if (profileFields[1]) {
      profileFields[1].label = translate["MOBILE_NUMBER"];
      profileFields[1].placeholder = translate["MOBILE_NUMBER"];
      profileFields[1].is_required = false;
    }
    if(profileFields[5]) {
      profileFields[5].is_required = true;
      profileFields[6].is_required = true;
      profileFields[7].is_required = true;
    }
    const validationSchema = generateValidationSchema(profileFields);

    this.setState({ profileFields, validationSchema });
  }

  handleUpdateProfile = async (values, actions) => {
    actions.setSubmitting(true);

    try {
      const payload = values;
      const submitAction = this.props.putUpdateProfile; 
      document.activeElement.blur();
      await submitAction(payload); 
    } catch (err) {
      console.log(err);
    } finally {
      actions.setSubmitting(false);
    }
  };

  render() {
    const { profileFields, validationSchema } = this.state;
    const { userProfile } = this.props.userStore;
    const { translate } = this.props.programStore;

    return (
      <FormikForm
        initialValues={{
          email: userProfile.email,
          mobile_number: "+" + userProfile.mobile_number,
          outlet_name: userProfile.metadata.outlet_name,
          outlet_address: userProfile.metadata.outlet_address,
          distributor_account_number: userProfile.metadata.distributor_account_number,
          hcp_code: userProfile.metadata.hcp_code,
          bank_name: userProfile.metadata.bank_name,
          bank_account_name: userProfile.metadata.bank_account_name,
          bank_account_number: userProfile.metadata.bank_account_number,
        }}
        onSubmitHandler={this.handleUpdateProfile}
        validationSchema={validationSchema}
        customSubmitText={translate["SAVE_CHANGES"]}
        formFields={profileFields}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userStore: state.userStore,
  programStore: state.programStore,
});

const mapDispatchToProps = ({ userStore, uiStore }) => ({
  ...userStore,
  ...uiStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UpdateProfile));
