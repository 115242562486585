import React from "react";
import { IonPage, IonContent } from "@ionic/react";
import { createStyles, withStyles } from "@material-ui/core";
import { connect } from "react-redux";

import Header from "../common/Header";

// FORMS
import RegisterPatientForm from "../components/Forms/RegisterPatient.form";

const styles = ({ spacing, palette }) =>
  createStyles({
    root: {
      margin: spacing(2),
      padding: spacing(2),
    },
    header: {
      fontFamily: "Arial",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "28px",
      marginBottom: "16px",
    },
    container: {
      padding: 16,
    },
  });

const RegisterPatientPage = ({ classes = {}, programStore, history }) => {
  const { translate } = programStore;
  return (
    <IonPage>
      <Header
        title={translate["REGISTER_PATIENT"]}
        backButton={true}
        backButtonFunction={() => {
          history.replace("/home", { direction: "forward" });
        }}
      />
      <IonContent className="content">
        <div className={classes.container}>
          <RegisterPatientForm />
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => ({
  programStore: state.programStore,
});

const mapDispatchToProps = ({ userStore, authStore }) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RegisterPatientPage));
