import React from "react";
import { IonPage, IonContent } from "@ionic/react";
import { createStyles, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import Header from "../common/Header";
import AboutContent from "../components/StaticContent/AboutContent";

const styles = ({ spacing, palette }) =>
  createStyles({
    root: {
      margin: spacing(1),
      padding: spacing(1),
    },
  });

const AboutPage = ({ classes = {}, programStore, userStore }) => {
  const { translate } = programStore;
  const { metadata } = userStore.userProfile;

  return (
    <IonPage>
      <Header title={translate["ABOUT"]} />
      <IonContent className="content">
        <div className={classes.root}>
          <AboutContent hcpCode={metadata.hcp_code} />
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => ({
  programStore: state.programStore,
  userStore: state.userStore,
});

const mapDispatchToProps = ({ userStore, authStore }) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AboutPage));
