import React from "react";
import { Typography, createStyles, withStyles } from "@material-ui/core";

const styles = ({ spacing, palette }) =>
	createStyles({
		header: {
			fontWeight: 700,
			marginBottom: spacing(2),
		},
	});

const OncologyContent = () => {
	return (
		<>
			<Typography variant="h6">Stellar</Typography>
			<br />
			<Typography variant="body2" paragraph>
				ONE Access adalah program digital yang dikembangkan untuk meningkatkan
				kepatuhan pasien Onkologi. Program ini diharapkan dapat membantu pasien
				mengelola kondisi kesehatan, melalui pemberian potongan harga langsung
				kepada pasien, informasi kesehatan terkait terapi dan pengingat
				kunjungan ke dokter.
				<br />
				<br />
				Diluncurkan pada September 2021, program ini akan berjalan selama
				minimal 12 bulan, dan pasien dapat membeli 1-4 box per transaksi sesuai
				peresepan yang diterima dengan maksimal 3 transaksi pembelian per bulan.
				<br />
				<br />
				Potongan harga diterapkan berdasarkan pada jumlah box yang dibeli oleh
				pasien.
			</Typography>
		</>
	);
};

const OverlapContent = () => {
	return (
		<>
			<Typography variant="h6">Stellar</Typography>
			<br />
			<Typography variant="body2" paragraph>
				Stellar adalah program digital yang dikembangkan untuk meningkatkan
				kepatuhan pasien gagal jantung dan/atau Onkologi. Program ini diharapkan
				dapat membantu pasien mengelola kondisi kesehatan melalui pemberian
				potongan harga langsung kepada pasien, informasi kesehatan terkait
				terapi dan pengingat kunjungan ke dokter.
				<br />
				<br />
				Diluncurkan pada September 2020, program ini akan berjalan selama
				minimal 12 bulan, dan pasien dapat membeli 1-4 box per transaksi sesuai
				peresepan yang diterima dengan maksimal 3 transaksi pembelian per bulan.
				<br />
				<br />
				Potongan harga diterapkan berdasarkan pada jumlah box yang dibeli oleh
				pasien.
			</Typography>
		</>
	);
};

const UperioContent = () => {
	return (
		<>
			<Typography variant="h6">Stellar</Typography>
			<br />
			<Typography variant="body2" paragraph>
				Stellar adalah program digital yang dikembangkan untuk mengingkatkan
				kepatuhan pasien gagal jantung dan membatu pasien mengelola kondisi
				kesehatan melalui pemberian potongan harga langsung kepada paien,
				informasi kesehatan terkait terapi gagal jantung, dan pengingat
				kunjungan ke dokter.
				<br />
				<br />
				Diluncurkan pada September 2020, program ini akan berjalan selama
				minimal 12 bulan, dan pasien dapat membeli 2-4 box per transaksi dengan
				maksimal 2 transaksi pembelian per bulan.
				<br />
				<br />
				Potongan harga diterapkan berdasarkan pada jumlah box yang dibeli oleh
				pasien.
			</Typography>
		</>
	);
};

const AboutContent = ({ classes = {}, hcpCode }) => {
	console.log(hcpCode);
	const render = () => {
		if (hcpCode === "RG_SOL") return OverlapContent();
		else if (hcpCode === "RG_SOC") return OncologyContent();
		else return UperioContent();
	};
	return <>{render()}</>;
};

export default withStyles(styles)(AboutContent);
