import { AppVersion } from "@ionic-native/app-version";
import { Market } from "@ionic-native/market";
import { Plugins } from "@capacitor/core";
import axios from "axios";
import stores from "../models/index.model";

const { Network, Modals, Device } = Plugins;
const mobileOS = ["android", "ios"];

export default {
	checkApplicationUpdate: async () => {
		try {
			// RETRIEVE current OS
			const { operatingSystem: currentOS } = await Device.getInfo();
			const { programs } = JSON.parse(process.env.REACT_APP_CONNECT_URLS);
			const PROGRAM_ID = JSON.parse(process.env.REACT_APP_KEYCLOAK_CONFIG).realm

			// return 'Disable updates for now';
			if (mobileOS.includes(currentOS)) {
				// CHECK connection status
				const networkStatus = await Network.getStatus();

				if (networkStatus.connected) {
					stores.dispatch.uiStore.showLoading(true);

					// RETRIEVE current app version
					const currentAppVersion = await AppVersion.getVersionNumber();

					// RETRIEVE latest app version available via API
					const { result: latestVersion } = (
						await axios.get(`${programs}/${PROGRAM_ID}/version`)
					).data;
					const latestAppVersion = latestVersion[`${currentOS}`].version;

					// FORCE UPDATE
					if (currentAppVersion < latestAppVersion) {
						const packageName = currentOS === 'android' ? "com.mclinica.nvid" : "id1532155944";

						// SHOW & OPEN app store for latest app version
						await Modals.alert({
							title: "Pembaruan Stellar",
							message: `Untuk terus menggunakan aplikasi Stellar, Anda harus memperbarui aplikasi ke versi terbaru. Klik OK untuk memperbarui aplikasi Anda.`,
						});
						Market.open(packageName);
					}
					stores.dispatch.uiStore.showLoading(false);
				}
			}
		} catch (err) {
			console.log(err.message);
			stores.dispatch.uiStore.showLoading(false);
			stores.dispatch.uiStore.showToast({
				message: err.message,
				position: "bottom",
				color: "warning",
			});
			throw err;
		}
	},
};
