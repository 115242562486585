import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0460A9",
    },
    secondary: {
      main: "#000000",
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: 13,
      },
    },
  },
});

export default theme;
