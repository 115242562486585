import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonPage,
  IonContent,
  IonInput,
  IonList,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonSelect,
  IonSelectOption,
  withIonLifeCycle
} from '@ionic/react';
import { search, checkmarkCircle, removeCircle } from 'ionicons/icons';
import { Paper, createStyles, withStyles } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/id';

import Header from '../common/Header';
import { startCase, toLower } from 'lodash';
import { Route, Routes, useNavigate } from 'react-router-dom';

moment.locale('id');

const styles = ({ spacing, palette }) =>
  createStyles({
    root: {
      margin: spacing(2),
      padding: spacing(2),
    },
    container: {
      padding: spacing(2),
      backgroundColor: '#EBEEF0',
      minHeight: '100%',
    },
    transactionItem: {
      display: 'flex',
      padding: 24,
      marginBottom: 16,
    },
    transactionIcon: {
      fontSize: 36,
      alignItems: 'center',
      display: 'flex',
      marginRight: 16,
    },
    transactionDate: {
      fontWeight: 'bold',
      fontSize: 16,
      color: '#0460A9',
      lineHeight: '18px',
      marginBottom: 10,
    },
    transactionDetail: {
      marginBottom: 4,
      color: '#333333',
      lineHeight: '12px',
    },
    brandNameDetail: {
      marginTop: 8,
      marginBottom: 6,
      color: '#333333',
      lineHeight: '12px',
      fontWeight: 650,
    },
    approved: {
      color: '#0460a9',
    },
    voided: {
      color: '#8a8a8f',
    },
    noTransactions: {
      position: 'absolute',
      top: '60%',
      left: '50%',
      transform: 'translate(-50%, -40%)',
    },
  });

class UploadReceiptListPage extends Component {
  state = {
    transactionList: [],
    searchText: '',
    month: '',
    day: '',
    year: '',
  };

  async componentDidMount() {
    
    // await this.props.getTransactionHistory();
    //  let tempList = this.props.transactionStore.transactionList.filter(
    //   (transaction) => transaction.transaction_status === 'pending'
    // );
    // this.setState({
    //   transactionList: tempList,
    // });
    
    
  }

  async ionViewDidEnter(){
    await this.props.getTransactionHistory();
    let tempList = this.props.transactionStore.transactionList.filter(
     (transaction) => transaction.transaction_status === 'pending'
   );
   this.setState({
     transactionList: tempList,
   });
  }

 


  formatter = (amount) => {
    const format = amount.toString().split('').reverse().join('');
    const convert = format.match(/\d{1,3}/g);
    return 'Rp ' + convert.join('.').split('').reverse().join('');
  };

  renderTransactionItem = (
    transactionBatchId,
    date,
    mobileNumber,
    skuLabel,
    status,
    productBrand,
    rebateAmount,
    receiptStatusText
  ) => {
    const { classes, showDialog } = this.props;
    const { translate } = this.props.programStore;
    const voidable =
      moment(date).add(1, 'days') >= moment() && status === 'approved';

    return (
      <Paper
        key={transactionBatchId}
        className={classes.transactionItem}
        onClick={(event) => {
          this.props.history.push(`/upload-receipt/${transactionBatchId}`);
        }}
      >
        <div>
          <div className={classes.transactionDate}>
            {moment(date).format('MMM D, YYYY [at] hh:mm:ss A')}
          </div>
          <div className={classes.transactionDetail}>+{mobileNumber}</div>
          <div className={classes.brandNameDetail}>{productBrand}</div>
          <div className={classes.transactionDetail}>
            {skuLabel} ({this.formatter(rebateAmount)})
          </div>
          <div className={classes.brandNameDetail}> {receiptStatusText} </div>
        </div>
      </Paper>
    );
  };

  handleVoidTransaction = async (transactionBatchId) => {
    await this.props.voidTransaction({
      transaction_batch_ids: [transactionBatchId],
    });
    await this.props.getTransactionHistory();
    this.filterList();
  };

  openReceiptUpload = (transactionBatchId) => {
    console.log(transactionBatchId);
  };

  formatSearch = (value) => {
    return value
      .toLowerCase()
      .replace(/[.,\\/#!$+%^&*;:{}=\-_`'~()|[\]]/g, '')
      .replace(/\s\s+/g, ' ')
      .split(' ')
      .filter(Boolean);
  };

  onTextSearch = (e) => {
    this.setState({ searchText: e.target.value });
    this.filterList();
  };

  onMonthChange = (e) => {
    this.setState({ month: e.detail.value });
    this.filterList();
  };

  onDayChange = (e) => {
    this.setState({ day: e.detail.value });
    this.filterList();
  };

  onYearChange = (e) => {
    this.setState({ year: e.detail.value });
    this.filterList();
  };

  filterList = () => {
    const { searchText, month, day, year } = this.state;
    const filteredTransactionList = [];
    const searchValue = this.formatSearch(searchText);

    this.props.transactionStore.transactionList.forEach((obj) => {
      let pushObj = true;
      const mobileNumber = obj?.metadata?.customer_mobile_number;
      const product = obj?.metadata?.products[0];
      const skuLabel = product
        ? `${product.product_sku_id.split('_')[1]} ${product.quantity} ${
            product.quantity > 1 ? 'boxes' : 'box'
          }`
        : '';

      // FILTER by search box
      searchValue.some((value) => {
        if (!mobileNumber.concat(skuLabel).replace(/\s/g, '').includes(value)) {
          pushObj = false;
          return false;
        }
      });
      if (searchValue?.length < 1) pushObj = true;
      // FILTER by month
      if (month && month !== moment(obj.created_at).format('MMMM')) {
        pushObj = false;
      }
      // FILTER by day
      if (day && day !== parseInt(moment(obj.created_at).format('D'), 10)) {
        pushObj = false;
      }
      // FILTER by year
      if (year && year !== moment(obj.created_at).format('YYYY')) {
        pushObj = false;
      }

      // FILTER by status
      if (obj.transaction_status !== 'pending') {
        console.log('Checking status', obj.transaction_status);
        pushObj = false;
      }

      console.log('Checking status', obj.transaction_status);
      if (pushObj) filteredTransactionList.push(obj);
    });

    this.setState({ transactionList: filteredTransactionList });
  };

  render() {
    const { classes = {}, history } = this.props;
    const { translate } = this.props.programStore;
    const { transactionList, searchText, month, day, year } = this.state;
    const months = moment.months();

    const years = ['2020', '2021', '2022', '2023', '2024', '2025'];

    return (
      <IonPage>
        <Header
          title={translate['UPLOAD_RECEIPT']}
          backButton={true}
          backButtonFunction={() => {
            history.replace('/home', { direction: 'forward' });
          }}
        />
        <IonContent className="content">
          <div id={'transaction_history'} className={classes.container}>
            {transactionList.map((transaction) => {
              const mobileNumber =
                transaction?.metadata?.customer_mobile_number;
              const product = transaction?.metadata?.products[0];
              const skuLabel = product
                ? `${product.product_sku_id.split('_')[1]} ${
                    product.quantity
                  } ${product.quantity > 1 ? 'boxes' : 'box'}`
                : '';
              const rebateAmount =
                transaction?.metadata?.total_discount_amount || 0;
              const productBrand = `${startCase(
                toLower(product?.product_sku_id.split('_')[0])
              )} ${product?.product_sub_type || ''}`;

              const receiptStatusText = transaction?.metadata?.receipt_photo?"Kuitansi sudah diunggah" : "Kuitansi belum diunggah" ;

              return this.renderTransactionItem(
                transaction.id,
                transaction.created_at,
                mobileNumber,
                skuLabel,
                transaction.transaction_status,
                productBrand,
                rebateAmount,
                receiptStatusText
              );
            })}
            {transactionList.length < 1 && (
              <div className={classes.noTransactions}>
                {translate['NO_TRANSACTIONS']}
              </div>
            )}
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

const mapStateToProps = (state) => ({
  programStore: state.programStore,
  transactionStore: state.transactionStore,
});

const mapDispatchToProps = ({ transactionStore, uiStore }) => ({
  ...transactionStore,
  ...uiStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withIonLifeCycle(UploadReceiptListPage)));
