import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/Loader/Loader.component";

const PublicRoute = (props) => {
  let { setRouteIsPrivate, component, ...rest } = props;

  useEffect(() => {
    setRouteIsPrivate(false);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        return React.createElement(component, props);
      }}
    />
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = ({ uiStore }) => ({
  ...uiStore,
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute);
