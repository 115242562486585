import React, { Component } from "react";
import NumberFormat from "react-number-format";

import {
  Grid,
  TextField as TextInput,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { ErrorMessage } from "formik";

const styles = ({ spacing, palette }) =>
  createStyles({
    formControl: {
      width: "100%",
      fontSize: 14,
    },
    helperText: {
      marginTop: 5,
      marginLeft: 5,
      marginRight: 5,
      fontStyle: "italic",
    },
    notchedOutline: {
      borderColor: palette.secondary.main,
    },
    label: {
      color: palette.secondary.main,
    },
    labelFit: {
      color: palette.secondary.main,
      fontSize: 11,
      paddingRight: 20,
      transform: "translate(14px, 18px) scale(1)",
    },
  });

class Input extends Component {
  render() {
    return (
      <TextInput
        {...this.props}
        type="text"
        variant="outlined"
        color="secondary"
        autoComplete="off"
      />
    );
  }
}

class NumberField extends Component {
  handleChange = (values) => {
    this.props.form.setFieldValue(
      this.props.field.name,
      values.value.substring(
        0,
        this.props.characterLimit ? this.props.characterLimit : 50
      )
    );
  };

  render() {
    const {
      props: {
        field,
        label,
        placeholder,
        commaSeparator = false,
        decimalScale = 0,
        helperText,
        required,
        classes,
      },
      handleChange,
    } = this;

    return (
      <Grid container spacing={4} key={field.name}>
        <Grid item sm={12} xs={12}>
          <NumberFormat
            id={this.props.field.name}
            name={this.props.field.name}
            type="text"
            label={
              label ? `${label}${required ? " *" : "(opsional)"}` : placeholder
            }
            allowNegative={false}
            allowLeadingZeros={true}
            customInput={Input}
            onValueChange={handleChange}
            onBlur={field.onBlur}
            thousandSeparator={commaSeparator}
            decimalScale={decimalScale}
            value={this.props.field.value || ""}
            helperText={helperText}
            fullWidth
            InputProps={{
              classes: { notchedOutline: classes.notchedOutline },
            }}
            InputLabelProps={{
              className: label.length > 50 ? classes.labelFit : classes.label,
            }}
          />

          <ErrorMessage
            name={field.name}
            render={(message) => {
              return (
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  color="error"
                >
                  {message}
                </Typography>
              );
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(NumberField);
