/* eslint-disable no-fallthrough */
import React, { Fragment } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, Typography, withStyles } from '@material-ui/core';

import { generateValidationSchema } from '../../utilities/validationSchema.utility';
import FormikForm from '../FormikForm/FormikForm.component';
import { isEmpty } from 'lodash';
import ProductSelection from './1.7.1+47/ProductSelection.form';

const styles = ({ spacing, palette }) =>
  createStyles({
    root: {
      margin: spacing(2),
      padding: spacing(2),
    },
    header: {
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight: 'bold',
      marginBottom: spacing(1),
    },
    header2: {
      fontFamily: 'Arial',
      fontStyle: 'normal',
      fontWeight: 'bold',
      marginBottom: spacing(1),
      marginTop: spacing(3),
    },
    inputLabel: {
      fontSize: 14,
      marginBottom: 12,
    },
    spanLabel: {
      fontSize: 14,
      marginBottom: 12,
      fontWeight: 'normal',
      marginLeft: 8,
    },
    container: {
      padding: 10,
    },
    toggleButton: {
      display: 'flex',
      flexFlow: 'row wrap',
      marginRight: spacing(2),
      textTransform: 'capitalize',
      minWidth: '64px',
      '&.MuiToggleButton-root.Mui-selected': {
        color: '#fff',
        backgroundColor: '#000',
      },

      '&:not(:last-child)': {
        marginLeft: '-1px',
      },
    },
    counterInput: {
      border: '1px solid rgba(0, 0, 0, 0.36)',
      boxSizing: 'border-box',
      borderRadius: '4px',
      fontSize: '16px',
      padding: 21,
      width: 56,
      height: 56,
      pointerEvents: 'none',
    },
    counterContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 12,
    },
    plus: {
      background: '#BF7D1A',
      color: '#fff',
      width: 30,
      height: 30,
      top: 0,
      left: 0,
      marginLeft: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        background: '#BF7D1A',
      },
      '&.MuiIconButton-root': {
        padding: '0px',
      },
    },
    minus: {
      background: '#7d0202',
      color: '#fff',
      width: 30,
      height: 30,
      top: 0,
      left: 0,
      marginLeft: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        background: '#7d0202',
      },
      '&.MuiIconButton-root': {
        padding: '0px',
      },
    },
    counterLabel: {
      fontStyle: 'italic',
      fontSize: 12,
    },
  });

const UploadReceiptPage = ({
  classes = {},
  transactionStore,
  putTransaction,
  programStore,
  userStore,
  match,
  changeCreatedAt,
  productStore,
  history,
}) => {
  const [drug, setDrug] = React.useState(null);
  const [subType, setSubType] = React.useState('');
  const [dosage, setDosage] = React.useState(null);
  const [boxes, setBoxes] = React.useState(1);
  const [specializeDoctorType, setSpecializeDoctorType] = React.useState('');
  const [isTabletSplitting, setIsTableSplitting] = React.useState(null);

  let { transactionFields } = transactionStore;
  let uploadReceiptFields = [];
  const { translate } = programStore;
  const { productList, productNames } = productStore;
  let tempFeild = { ...transactionFields[1], is_visible: 1, is_default: 1 };
  uploadReceiptFields.push(tempFeild);

  React.useEffect(() => {
    //if (isEmpty(productList)) return history.push('/home');
  });

  React.useEffect(() => {
    let productBrand = [];
    if (drug && drug.brand_name && productList[drug.brand_name]) {
      productBrand = productList[drug.brand_name];
      const { product_configuration_metadata } = dosage
        ? productBrand.find((product) => product.product_sku_id === dosage)
        : productBrand[0];

      if (subType === 'Femara' && drug.brand_name === 'kryxana') {
        setBoxes(3);
      } else {
        setBoxes(product_configuration_metadata.base ?? 1);
      }
    }
  }, [transactionFields]);

  const handleSkuSelection = (event, value, type) => {
    /**
     * Fall through switch condition
     * e.g
     * switch (type)
     *   case a: console.log('a')
     *   case b: console.log('b')
     *   case c: console.log('c')
     *   default: break;
     * when type == 'a', then result will be 'a\nb\nc'
     * type === 'b', result is 'b\nc',
     * type === 'c', result is 'c'
     */
    switch (type) {
      case 'drug': {
        setDrug(value);
      }
      case 'subtype': {
        if (type !== 'subtype') {
          setSubType(null);
        } else {
          setSubType(value);
        }
      }
      case 'dosage': {
        if (type !== 'dosage') {
          setDosage(null);
        } else {
          setDosage(value);
          break;
        }
        setBoxes(1);
      }
      case 'specialize-type': {
        if (type !== 'specialize-type') {
          setSpecializeDoctorType(null);
        } else {
          setSpecializeDoctorType(value);
          break;
        }
      }
      case 'tablet-split': {
        if (type !== 'tablet-split') {
          setIsTableSplitting(null);
        } else {
          setIsTableSplitting(value);
        }
      }
      default:
        break;
    }
  };

  const handleBoxes = (value) => {
    if (!dosage) {
      return;
    }
    const newBoxes = boxes + value;
    const { maximum_purchase_allowed, product_configuration_metadata } =
      productList[drug.brand_name].find(
        (product) => product.product_sku_id === dosage
      );
    let newBase = product_configuration_metadata.base;
    let newSkip = product_configuration_metadata.skip;
    if (subType === 'Femara' && drug.brand_name === 'kryxana') {
      newSkip = [...newSkip, 1, 2];
      newBase = 3;
    }

    consolidateBoxes(
      newBoxes,
      value,
      maximum_purchase_allowed,
      newSkip,
      newBase
    );
  };

  const consolidateBoxes = (
    newBoxes,
    value,
    maximum = 5,
    skip = 0,
    base = 1
  ) => {
    if (newBoxes < base) return setBoxes(base);
    if (skip !== 0 && skip.includes(newBoxes) && value !== -1)
      return setBoxes(maximum);
    if (newBoxes > 0 && newBoxes < maximum + 1) {
      while (skip !== 0 && skip.includes(newBoxes)) {
        --newBoxes;
        --value;
      }
      setBoxes(boxes + value);
    }
  };

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      values.transaction_id = match.params.transactionId;
      values.type = 'upload_receipt';

      let responseReceived = await putTransaction(values);

      console.log('responseReceived', responseReceived);

      // Reset form and state
      actions.resetForm({
        doctor_name: '',
        hospital_clinic_name: '',
        prescription_photo: '',
      });

      setDrug(null);
      setDosage(null);
      setSubType(null);
      setSpecializeDoctorType(null);
      setIsTableSplitting(null);

      history.push(`/home`);
    } catch (err) {
      console.log(err);
    } finally {
      actions.setSubmitting(false);
    }
  };

  /**
   * Determine whether Formik children required field valid or not.
   *
   * @returns {Boolean}
   */
  const isProductSelectionValid = () => {
    if (drug && drug.brand_name === 'uperio') {
      return dosage && boxes && specializeDoctorType && isTabletSplitting;
    }
    return !!(
      dosage &&
      boxes &&
      (dosage.toLowerCase().includes('kryxana') ||
      dosage.toLowerCase().includes('rebozet')
        ? subType
        : true)
    );
  };

  return (
    <div>
      <Typography className={classes.header} variant="h5">
        {translate['UPLOAD_RECEIPT']}
      </Typography>
      <FormikForm
        initialValues={{
          doctor_name: '',
          hospital_clinic_name: '',
          prescription_photo: '',
          receipt_photo: '',
        }}
        onSubmitHandler={handleSubmit}
        validationSchema={generateValidationSchema(uploadReceiptFields)}
        customSubmitText={translate['CONFIRM']}
        formFields={uploadReceiptFields}
        //additionalFieldValid={isProductSelectionValid()}
      >
        {/* <Typography className={classes.header2} variant="h5">
          {translate['PRODUCT_SELECTION']}
        </Typography>
        <ProductSelection
          translate={translate}
          handleSkuSelection={handleSkuSelection}
          productNames={productNames}
          classes={classes}
          drug={drug}
          productList={productList}
          subType={subType}
          dosage={dosage}
          boxes={boxes}
          handleBoxes={handleBoxes}
          specializeType={specializeDoctorType}
          isTabletSplitting={isTabletSplitting}
        /> */}
      </FormikForm>
    </div>
  );
};

const mapStateToProps = (state) => ({
  programStore: state.programStore,
  transactionStore: state.transactionStore,
  productStore: state.productStore,
});

const mapDispatchToProps = ({ transactionStore }) => ({
  ...transactionStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(UploadReceiptPage)));
