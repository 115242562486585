import * as Yup from "yup";

export const generateValidationSchema = (formFields, customScheme = {}) => {
  let object = {};

  formFields &&
    formFields.forEach((field) => {
      let schema = Yup.string();

      if (field.is_required) {
        schema = schema.concat(Yup.string().required(field.error_message));
      }

      if (field.regex_validations && field.regex_validations.length > 0) {
        field.regex_validations.forEach((validation) => {
          const regex = new RegExp(validation.regex);
          const regexSchema = Yup.string().matches(
            regex,
            validation.error_message
          );
          schema = schema.concat(regexSchema);
        });
      }

      object[field.name] = schema;
    });

  return Yup.object().shape({
    ...object,
    ...customScheme,
  });
};
