import React from "react";
import { connect } from "react-redux";
import { createStyles, withStyles } from "@material-ui/core";
import { IonLoading } from "@ionic/react";

const styles = ({ spacing, palette }) =>
  createStyles({
    loaderContainer: {
      width: "100%",
      height: "100vh",
      position: "relative",
      background: "#ffffff",
      zIndex: "999",
    },
    loaderContent: {
      position: "absolute",
      top: "50%",
      left: "50%",
      msTransform: "translateY(-50%) translateX(-50%)",
      transform: "translateY(-50%) translateX(-50%)",
      textAlign: "center",
    }
  });

const Loader = (props) => (
  <div className={props.classes.loaderContainer}>
    <div className={props.classes.loaderContent}>
      <IonLoading message={'Please wait...'} isOpen={true}/>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  uiStore: state.uiStore,
});

export default connect(mapStateToProps)(withStyles(styles)(Loader));
