import React, { Component } from "react";
import {
  Container,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { ErrorMessage } from "formik";

class CheckboxField extends Component {
  render() {
    const { field, label, disabled, helperText } = this.props;

    return (
      <Container>
        <Grid container spacing={4} key={field.name}>
          <FormControlLabel
            control={
              <Checkbox
                checked={field.value || false}
                onChange={field.onChange}
                onBlur={field.onBlur}
                name={field.name}
                color="primary"
                disabled={disabled}
              />
            }
            label={label}
          />

          {helperText && (
            <Typography variant="caption" display="block" gutterBottom>
              {helperText}
            </Typography>
          )}

          <ErrorMessage
            name={field.name}
            render={(message) => {
              return (
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  color="error"
                >
                  {message}
                </Typography>
              );
            }}
          />
        </Grid>
      </Container>
    );
  }
}

export default CheckboxField;
