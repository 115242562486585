import { userService } from "../services/index.service";
import stores from "./index.model";
import generateErrorMessage from "../utilities/errorMessage.utility";

export default {
  state: {
    userProfile: {
      campaign_id: "",
      email: "",
      email_verified_at: null,
      first_name: "",
      full_name: "",
      id: "",
      is_registration_completed: false,
      is_tester: false,
      last_name: "",
      metadata: {},
      middle_name: "",
      mobile_number: "",
      mobile_verified_at: null,
      program_id: "",
      push_key: "",
      status: "",
      user_type: "",
      created_at: new Date(),
      updated_at: new Date(),
      deleted_at: null,
    },
    registeredUserToken: {
      access_token: "",
      refresh_token: "",
    },
    userFields: [],
    isLoadingProfile: false,
  },

  reducers: {
    setUserProfile(state, payload) {
      return {
        ...state,
        userProfile: payload,
      };
    },
    setRegisteredUserToken(state, payload) {
      return {
        ...state,
        registeredUserToken: payload,
      };
    },
    resetRegisteredUserToken(state) {
      return {
        ...state,
        registeredUserToken: {
          access_token: "",
          refresh_token: "",
        },
      };
    },
    setUserFields(state, payload) {
      return {
        ...state,
        userFields: payload,
      };
    },
    setIsLoadingProfile(state, payload) {
      return {
        ...state,
        isLoadingProfile: payload,
      };
    },
  },

  effects: (dispatch) => ({
    getUserProfile: async () => {
      try {
        const { result } = await userService.getUserProfile();

        dispatch.userStore.setUserProfile(result);
        dispatch.userStore.setIsLoadingProfile(false);
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showLoading(false);
        stores.dispatch.authStore.logoutUser();
        // stores.dispatch.uiStore.showToast({
        //   message: generateErrorMessage(err),
        //   color: "warning",
        // });
        throw err;
      }
    },

    getUserFields: async () => {
      try {
        const userFields = await userService.getUserFields();

        dispatch.userStore.setUserFields(userFields);
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },
    postRegisterUser: async (payload) => {
      try {
        stores.dispatch.uiStore.showLoading(true);
        const { message, result } = await userService.postRegisterUser({
          ...payload,
          user_type: window.config.USER_TYPE,
        });

        dispatch.userStore.setRegisteredUserToken({
          access_token: result.access_token,
          refresh_token: result.refresh_token,
        });
        const loggedinUser = window.apiClient.setLoggedInUser(
          {
            accessToken: result.access_token,
            refreshToken: result.refresh_token,
          },
          true
        );
        dispatch.authStore.setLoggedUser(loggedinUser);
        stores.dispatch.uiStore.showLoading(false);
        console.log(message, result);
      } catch (err) {
        console.log(err);
        stores.dispatch.uiStore.showLoading(false);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    postVerifyUser: async (payload) => {
      try {
        stores.dispatch.uiStore.showLoading(true);
        const response = await userService.postVerifyUser(payload);

        dispatch.userStore.resetRegisteredUserToken();
        await stores.dispatch.userStore.getUserProfile();
        stores.dispatch.uiStore.showLoading(false);
        stores.dispatch.authStore.setIsLoading(false);
        console.log(response);
        stores.dispatch.uiStore.showDialog({
          message: response.message,
        });
      } catch (err) {
        console.log(err);
        stores.dispatch.uiStore.showLoading(false);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    postResendVerification: async ({ token }) => {
      try {
        const { message } = await userService.postResendVerification();

        stores.dispatch.uiStore.showDialog({
          message: "Email verifikasi telah dikirim ulang",
        });
      } catch (err) {
        console.log(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    putUpdateProfile: async (payload) => {
      try {
        const { result } = await userService.putUpdateProfile(payload);

        dispatch.userStore.setUserProfile(result);
        stores.dispatch.uiStore.showDialog({
          message: window.translate["YOUR_PROFILE_HAS_BEEN_UPDATED"],
        });
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    putUpdatePassword: async (payload) => {
      try {
        await userService.postUpdatePassword(payload);

        stores.dispatch.uiStore.showDialog({
          message: window.translate["YOUR_PASSWORD_HAS_BEEN_UPDATED"],
        });
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },

    postDeactivateAccount: async () => {
      try {
        const { message } = await userService.postDeactivateAccount();

        stores.dispatch.uiStore.showDialog({
          message,
        });
      } catch (err) {
        console.error(err);
        stores.dispatch.uiStore.showToast({
          message: generateErrorMessage(err),
          color: "warning",
        });
        throw err;
      }
    },
  }),
};
