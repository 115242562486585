import { Field } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import FormikForm from "../../components/FormikForm/FormikForm.component";
import { HCP_CODE_LIST } from "../../utilities/constants";
import { generateValidationSchema } from "../../utilities/validationSchema.utility";
import { CheckboxField } from "../FormFields";

class UserRegistration extends Component {
  constructor(props) {
    super(props);

    const customUserFields = this.customizeUserFields();
    const userFieldsList = [
      customUserFields,
      customUserFields.filter((field) => field.name !== 'bank_name' && field.name !== 'bank_account_name' && field.name !== 'bank_account_number'),
    ];

    this.state = {
      userFieldsList,
      userFields: userFieldsList[1],
      hcpCode: '',
      showTnc: false,
    }

    this.handleChangeHcpCode = this.handleChangeHcpCode.bind(this);
  }

  /**
   * Custom specific bank details fields.
   * 
   * @description
   * bank details fields is set optional in db because partial cases only
   * that require bank fields to be mandatory. For RG_SOC case, user don't
   * need to fill the bank details so that it will return error in backend if
   * db set to mandatory for these fields. So custom behavior will be coded in
   * frontend.
   */
  customizeUserFields = () => {
    return this.props.userStore.userFields.map((field) => {
      const copiedField = Object.assign({}, field)
      if(field.name === 'bank_name' || field.name === 'bank_account_name' || field.name === 'bank_account_number') {
        copiedField.is_required = true;
      }
      return copiedField;
    })
  }

  handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    try {
      const { history } = this.props;
      document.activeElement.blur();
      await this.props.postRegisterUser({
        ...values,
        mobile_number: values.mobile_number,
      });
      history.replace("/verify", { direction: "forward" });
    } catch (err) {
      console.log(err);
    } finally {
      actions.setSubmitting(false);
    }
  };

  /**
   * Handle hcp code change event handler. 
   * 
   * @param {String} value New updated value.
   */
  handleChangeHcpCode = (value) => {
    if (value === HCP_CODE_LIST.RG_SUP || value === HCP_CODE_LIST.RG_SOL) {
      this.setState({ userFields: this.state.userFieldsList[0] });
    } else {
      this.setState({ userFields: this.state.userFieldsList[1] });
    }
    this.setState({ hcpCode: value });
    this.setState({showTnc: Object.values(HCP_CODE_LIST).includes(value)});
  }

  /**
   * Dynamically render the tnc link.
   * 
   * @returns DOM
   */
  renderTncLink = () => {
    const { hcpCode } = this.state;
    switch (hcpCode) {
      case 'RG_SOC':
      case 'RG_SOL':
        return (<Link to="/terms?overlap=true">
          Syarat dan Ketentuan serta Kebijakan Privasi
        </Link>);
      default:
        return (<Link to="/terms">
          Syarat dan Ketentuan serta Kebijakan Privasi
        </Link>);
    }
  }

  render() {
    const { userFields } = this.props.userStore;
    const { translate } = this.props.programStore;

    return (
      <FormikForm
        initialValues={{ agreement_check: false }}
        onSubmitHandler={this.handleSubmit}
        validationSchema={generateValidationSchema(this.state.userFields, {
          agreement_check: Yup.bool().oneOf(
            [true],
            translate["CONFIRM_TERMS_AND_CONDITIONS"]
          ),
          confirm_password: Yup.string()
            .oneOf([Yup.ref("password")], translate["CONFIRM_PASSWORD_MATCH"])
            .matches(/^.{8,}$/, translate["CONFIRM_PASSWORD_8_CHARS"])
            .matches(/.*[A-Z]/, translate["CONFIRM_PASSWORD_UPPER_CASE_CHARS"])
            .matches(/.*[a-z]/, translate["CONFIRM_PASSWORD_LOWER_CASE_CHARS"])
            .matches(/^.*[0-9]/, translate["CONFIRM_PASSWORD_NUMBERS"])
            .matches(
              /^.*[@#$%^&+=!*()?]/,
              translate["CONFIRM_PASSWORD_SPECIAL_CHARS"]
            )
            .required(translate["CONFIRM_PASSWORD_REQUIRED"]),
        })}
        formFields={this.state.userFields}
        customSubmitText={translate["REGISTER"]}
        onChangeHcpCode={this.handleChangeHcpCode}
      >
        {this.state.showTnc && <Field
          name="agreement_check"
          component={CheckboxField}
          label={
            <div style={{ padding: 16, marginTop: 16 }}>
              <span>
                Saya menyetujui{" "}
                {this.renderTncLink()}
              </span>
            </div>
          }
          required
        />}
      </FormikForm>
    );
  }
}

const mapStateToProps = (state) => ({
  programStore: state.programStore,
  userStore: state.userStore,
});

const mapDispatchToProps = ({ programStore, userStore, uiStore }) => ({
  ...programStore,
  ...userStore,
  ...uiStore,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserRegistration);
