import React from 'react';
import { connect } from 'react-redux';
import { IonToast } from '@ionic/react';

const Toast = (props) => {
	return (
		<IonToast {...props.uiStore.toastConfig} />
	);
}

const mapStateToProps = state => ({
  uiStore: state.uiStore
});

export default connect(mapStateToProps)(Toast);