import _ from "lodash";
import { Plugins } from "@capacitor/core";

const { Network } = Plugins;

const generateErrorMessage = (error) => {
  let errorMessage = "";
  if (error.response && error.response.data) {
    let inputErrors;
    if (error.response.data.inputs) {
      inputErrors = _.values(error.response.data.inputs);
    }
    errorMessage = inputErrors
      ? inputErrors.join("\n")
      : error.response.data.message;
  } else {
    errorMessage = error.message;
  }

  const networkStatus = Network.getStatus();

  if (!networkStatus.connected && errorMessage === "Network Error") {
    errorMessage = window.translate["YOU_ARE_CURRENTLY_OFFLINE"];
  }

  return errorMessage;
};

export default generateErrorMessage;
