import React from "react";
import { useHistory } from "react-router-dom";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonButton,
  IonMenuToggle,
  IonMenuButton,
} from "@ionic/react";
import { ChevronLeft } from "@material-ui/icons";
import "./Header.css";

const Header = ({
  backButton = false,
  backToHome = true,
  menuButton = true,
  backButtonFunction = null,
  title = "",
}) => {
  let history = useHistory();
  return (
    <IonHeader className={"header-noshadow"}>
      <IonToolbar className={"toolbar-noshadow"}>
        <IonButtons slot="start" style={{ position: "absolute" }}>
          {backButton && (
            <IonButton
              onClick={
                backButtonFunction
                  ? backButtonFunction
                  : backToHome
                  ? () => history.replace("/home")
                  : history.goBack
              }
              color={"light"}
            >
              <ChevronLeft className="text-white" />
            </IonButton>
          )} 
          {
            !backButton && menuButton && 
            (
              <IonMenuToggle>
              <IonMenuButton color={"light"} />
              </IonMenuToggle>
            )
          }
          
        </IonButtons>
        <IonTitle className={"toolbar-title"} style={{ paddingLeft: 70 }}>
          {title}
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
