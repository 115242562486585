import { Box, createStyles, Link, withStyles } from "@material-ui/core";
import { Field } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { CodeInputField } from "../../components/FormFields";
import FormikForm from "../../components/FormikForm/FormikForm.component";


const styles = ({ spacing, palette }) =>
  createStyles({
    button: {
      backgroundColor: palette.primary.dark,
      "&:hover": {
        backgroundColor: "#00174a",
      },
    },
    resendCodeTextBox: {
      marginTop: spacing(2),
      marginBottom: spacing(4),
      textAlign: "center",
    },
    resendCodeText: {
      fontSize: 14,
      "&:hover": {
        cursor: "pointer",
      },
    },
  });

class UserVerification extends Component {
  handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    try {
      const { history } = this.props;

      document.activeElement.blur();

      await this.props.postVerifyUser(values);

      history.replace("/home");
    } catch (err) {
      console.log(err);
    } finally {
      actions.setSubmitting(false);
    }
  };

  render() {
    const { classes, programStore } = this.props;
    const { translate } = programStore;
    const verificationSchema = Yup.object().shape({
      verification_code: Yup.string()
        .matches(
          "^.{8,}$",
          translate["VERIFICATION_CODE_MUST_BE_8_CHARACTERS_LONG"]
        )
        .required(translate["PLEASE_INPUT_YOUR_VERIFICATION_CODE"]),
    });

    return (
      <FormikForm
        buttonSize={this.props.buttonSize}
        initialValues={{}}
        onSubmitHandler={this.handleSubmit}
        validationSchema={verificationSchema}
        customSubmitText={translate["SUBMIT"]}
      >
        <Field
          name="verification_code"
          type="text"
          component={CodeInputField}
          placeholder="Verification Code"
          label={translate["VERIFICATION_CODE"]}
          upperCase
          required
        />
        <Box className={classes.resendCodeTextBox}>
          <Link
            className={classes.resendCodeText}
            onClick={this.props.postResendVerification}
          >
            {translate["RESEND_VERIFICATION_CODE"]}
          </Link>
        </Box>
      </FormikForm>
    );
  }
}

const mapStateToProps = (state) => ({
  programStore: state.programStore,
});

const mapDispatchToProps = ({ programStore, userStore }) => ({
  ...programStore,
  ...userStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UserVerification));
