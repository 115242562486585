import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormikForm from "../../components/FormikForm/FormikForm.component";
import { generateValidationSchema } from "../../utilities/validationSchema.utility";

class RegisterPatient extends Component {
  async componentDidMount() {
    await this.props.getCustomerFields();
  }

  handleSubmit = async (values, { resetForm }) => {
    if (!values.email_address) {
      values.email_address = "";
    }

    document.activeElement.blur();

    try {
      const { history } = this.props;
      await this.props.registerPatient({
        ...values,
        mobile_number: values.mobile_number,
      });
      resetForm({});
      history.push("/home");
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    let { customerFields } = this.props.customerStore;
    let { translate } = this.props.programStore;

    return (
      <FormikForm
        onSubmitHandler={this.handleSubmit}
        validationSchema={generateValidationSchema(customerFields)}
        formFields={customerFields}
        customSubmitText={translate["SUBMIT"]}
        initialValues={{ name: "", chosen_language: "my" }}
      ></FormikForm>
    );
  }
}

const mapStateToProps = (state) => ({
  customerStore: state.customerStore,
  programStore: state.programStore,
});

const mapDispatchToProps = ({ customerStore }) => ({
  ...customerStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RegisterPatient));
