import { Field } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { TextField } from "../../components/FormFields";
import FormikForm from "../../components/FormikForm/FormikForm.component";

class UpdatePassword extends Component {
  handleUpdatePassword = async (values, actions) => {
    actions.setSubmitting(true);

    try {
      const payload = values;
      const submitAction = this.props.putUpdatePassword;
      document.activeElement.blur();
      await submitAction(payload);
      actions.resetForm();
    } catch (err) {
      console.log(err);
    } finally {
      actions.setSubmitting(false);
    }
  };

  render() {
    const { translate } = this.props.programStore;

    return (
      <FormikForm
        onSubmitHandler={this.handleUpdatePassword}
        validationSchema={Yup.object().shape({
          old_password: Yup.string()
            .matches(/^.{8,}$/, translate["OLD_PASSWORD_8_CHARS"])
            .matches(/.*[A-Z]/, translate["OLD_PASSWORD_UPPER_CASE_CHARS"])
            .matches(/.*[a-z]/, translate["OLD_PASSWORD_LOWER_CASE_CHARS"])
            .matches(/^.*[0-9]/, translate["OLD_PASSWORD_NUMBERS"])
            .matches(
              /^.*[@#$%^&+=!*()?]/,
              translate["OLD_PASSWORD_SPECIAL_CHARS"]
            )
            .required(translate["OLD_PASSWORD_REQUIRED"]),
          password: Yup.string()
            .when("old_password", {
              is: (old_password) => !!old_password,
              then: Yup.string().notOneOf(
                [Yup.ref("old_password")],
                translate["YOUR_NEW_PASSWORD_SHOULD_NOT_MATCH_OLD_PASSWORD"]
              ),
            })
            .matches(/^.{8,}$/, translate["PASSWORD_8_CHARS"])
            .matches(/.*[A-Z]/, translate["PASSWORD_UPPER_CASE_CHARS"])
            .matches(/.*[a-z]/, translate["PASSWORD_LOWER_CASE_CHARS"])
            .matches(/^.*[0-9]/, translate["PASSWORD_NUMBERS"])
            .matches(/^.*[@#$%^&+=!*()?]/, translate["PASSWORD_SPECIAL_CHARS"])
            .required(translate["PASSWORD_REQUIRED"]),
          confirm_password: Yup.string()
            .when("old_password", {
              is: (old_password) => !!old_password,
              then: Yup.string().notOneOf(
                [Yup.ref("old_password")],
                translate["CONFIRM_PASSWORD_SHOULD_NOT_MATCH_OLD_PASSWORD"]
              ),
            })
            .when("password", {
              is: (password) => !!password,
              then: Yup.string().oneOf(
                [Yup.ref("password")],
                translate["CONFIRM_PASSWORD_MATCH"]
              ),
            })
            .matches(/^.{8,}$/, translate["CONFIRM_PASSWORD_8_CHARS"])
            .matches(/.*[A-Z]/, translate["CONFIRM_PASSWORD_UPPER_CASE_CHARS"])
            .matches(/.*[a-z]/, translate["CONFIRM_PASSWORD_LOWER_CASE_CHARS"])
            .matches(/^.*[0-9]/, translate["CONFIRM_PASSWORD_NUMBERS"])
            .matches(
              /^.*[@#$%^&+=!*()?]/,
              translate["CONFIRM_PASSWORD_SPECIAL_CHARS"]
            )
            .required(translate["CONFIRM_PASSWORD_REQUIRED"]),
        })}
        customSubmitText={translate["SAVE_CHANGES"]}
      >
        <Field
          name="old_password"
          component={TextField}
          type="password"
          placeholder={translate["OLD_PASSWORD"]}
          required
        />
        <Field
          name="password"
          component={TextField}
          type="password"
          placeholder={translate["NEW_PASSWORD"]}
          helperText={translate["PASSWORD_HINT"]}
          required
        />
        <Field
          name="confirm_password"
          component={TextField}
          type="password"
          placeholder={translate["CONFIRM_PASSWORD"]}
          required
        />
      </FormikForm>
    );
  }
}

const mapStateToProps = (state) => ({
  programStore: state.programStore,
});

const mapDispatchToProps = ({ userStore }) => ({
  ...userStore,
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
