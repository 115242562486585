import { Log, UserManager, WebStorageStateStore } from "oidc-client";
import { AppVersion } from "@ionic-native/app-version";
import { Plugins } from "@capacitor/core";
import "../config";

const { Device } = Plugins;

class AuthService {
  constructor() {
    this.userManager = (settings) => new UserManager(settings);

    Log.logger = console;
    Log.level = Log.INFO;
  }

  getUser = async () => {
    const settings = await getSettings();
    return this.userManager(settings).getUser();
  };

  login = async () => {
    const settings = await getSettings();
    return this.userManager(settings).signinRedirect();
  };

  renewToken = async () => {
    const settings = await getSettings();
    return this.userManager(settings).signinSilent();
  };

  logout = async () => {
    const settings = await getSettings();
    return this.userManager(settings).signoutRedirect();
  };
}

const getSettings = async () => {
  let packageName = null;
  const { operatingSystem, platform } = await Device.getInfo();
  if (["android", "ios"].includes(operatingSystem) && platform !== "web") {
    packageName = await AppVersion.getPackageName();
  }

  const { protocol, hostname, port } = window.location;
  const host = packageName
    ? `${packageName}:/`
    : `${protocol}//${hostname}${port && ":" + port}`;

  const settings = {
    authority: `${window.config.KEYCLOAK_CONFIG.auth_server_url}/realms/${window.config.KEYCLOAK_CONFIG.realm}`,
    client_id: window.config.KEYCLOAK_CONFIG.client_id,
    redirect_uri: `${host}/signin-callback.html`,
    silent_redirect_uri: `${host}/silent-renew.html`,
    post_logout_redirect_uri: `${host}/login`,
    response_type: "code",
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  };

  return settings;
};

export default new AuthService({});
