window.config = {
  KEYCLOAK_CONFIG: JSON.parse(process.env.REACT_APP_KEYCLOAK_CONFIG),
  CONNECT_URLS: JSON.parse(process.env.REACT_APP_CONNECT_URLS),
  PROGRAM_ID: JSON.parse(process.env.REACT_APP_KEYCLOAK_CONFIG).realm,
  USER_TYPE: "pharmacist",
  RECENTLY_VIEWED_LIMIT: 10,
  APP_ID: null,
  REQUEST_HEADER_ACCEPTED_LANGUAGE: "id",
  NETWORK_CONNECTED: true,
  VERSION_CODE: 47,
};
