import React, { useEffect } from 'react';
import { AuthService } from '../services/AuthService';
import Loader from "../components/Loader/Loader.component";
const EndRedirect = (props) => {
    useEffect(() => {
        AuthService.Instance.EndSessionCallBack();
        props.history.replace('login');
    }, []);
    return <Loader/>;
};
export default EndRedirect;