import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function AlertDialog(props) {
  const { isOpen, message, actions } = props.uiStore.dialogConfig;

  const handleClose = () => {
    props.closeDialog();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Stellar"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions && actions.length > 0 ? (
          actions.map((action) => (
            <Button
              onClick={async () => {
                handleClose();
                action.onClick && (await action.onClick());
              }}
              color={action.color ? action.color : "primary"}
              style={action.style ? action.style : {}}
              autoFocus
            >
              {action.label}
            </Button>
          ))
        ) : (
          <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          autoFocus
        >
          OK
        </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  uiStore: state.uiStore,
  programStore: state.programStore,
});

const mapDispatchToProps = ({ uiStore }) => ({
  ...uiStore,
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);
