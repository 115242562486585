import { groupBy } from "lodash";

export default {
	state: {
		productList: {},
		productNames: [],
		productConfig: {},
	},
	reducers: {
		setProductList(state, payload) {
			return { ...state, productList: payload };
		},
		setProductConfig(state, payload) {
			return { ...state, productConfig: payload };
		},
		setProductNames(state, payload) {
			return { ...state, productNames: payload };
		},
	},
	effects: (dispatch) => ({
		consolidateState(payload) {
			dispatch.productStore.setProductConfig(payload?.productCampaign);
			delete payload?.productCampaign;

			let products = Object.entries(payload)
				.map((e) => ({ ...e[1] }))
				.sort((a, b) => a.order - b.order);
			products = groupBy(products, (p) => p.brand_id);
			dispatch.productStore.setProductList(products);

			const names = Object.entries(products)
				.sort(
					([, a], [, b]) =>
						a[0].brand.brand_metadata.order - b[0].brand.brand_metadata.order
				)
				.map(([k, v]) => {
					const molecule_name = v[0].brand.brand_metadata.molecule_name;
					const brand_name = k;

					return { brand_name, molecule_name, maximum_purchase_allowed: v[0].maximum_purchase_allowed };
				});

			dispatch.productStore.setProductNames(names);
		},
	}),
};
