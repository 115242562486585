import React from "react";
import { connect } from "react-redux";
import { IonPage, IonContent } from "@ionic/react";
import Header from "../common/Header";
import FAQContent from "./../components/StaticContent/FAQContent";

const FAQPage = ({ programStore, userStore }) => {
  const { translate } = programStore;
  const { metadata } = userStore.userProfile;

  return (
    <IonPage>
      <Header title={translate["USER_GUIDE"]} />
      <IonContent className="content">
        <FAQContent hcpCode={metadata.hcp_code} />
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => ({
  programStore: state.programStore,
  userStore: state.userStore,
});

const mapDispatchToProps = ({ userStore, authStore }) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FAQPage);
