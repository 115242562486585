import { IonContent, IonPage } from "@ionic/react";
import {
  createStyles,
  withStyles
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import Header from "../common/Header";
import RedeemDiscountForm from "../components/Forms/RedeemDiscount.form";

const styles = ({ spacing, palette }) =>
  createStyles({
    root: {
      margin: spacing(2),
      padding: spacing(2),
    },
    header: {
      fontFamily: "Arial",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "20px",
      marginBottom: "16px",
    },
    content: {
      padding: 16,
    },
  });

const RedeemDiscountPage = ({ classes = {}, programStore, history }) => {
  const { translate } = programStore;

  return (
    <IonPage>
      <Header
        title={translate["REDEEM_DISCOUNT"]}
        backButton={true}
        backButtonFunction={() => {
          history.replace("/patientcode", { direction: "forward" });
        }}
      />
      <IonContent>
        <div className={classes.content}>
          <RedeemDiscountForm history={history}></RedeemDiscountForm>
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => ({
  programStore: state.programStore,
  productStore: state.productStore,
});

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(RedeemDiscountPage));
