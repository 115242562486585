import React from "react";
import {
  Container,
  Typography,
  createStyles,
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const styles = ({ spacing, palette }) =>
  createStyles({
    root: {
      paddingTop: spacing(2),
    },
    header: {
      marginBottom: spacing(2),
      color: "grey",
    },
  });

const FAQContent = ({ classes = {}, hcpCode }) => {
  /**
   * Render number of boxes section content.
   * 
   * @returns {DOM}
   */
  const renderNumberOfBoxesGuide = () => {
    switch (hcpCode) {
      case 'RG_SOL':
      case 'RG-SOL':
        return (<Typography>
          Untuk program Uperio, pembelian minimum adalah 2 box, dan maksimum adalah 4 box per transaksi. Diperbolehkan untuk melakukan 2 transaksi per bulan dengan kemungkinan total 8 box pembelian per bulan.

          Untuk program Onkologi, terdapat diskon khusus untuk pembelian dosis penuh sebulan maupun pembelian 1 box. Pembelian minimum adalah 1 box (terkecuali Votrient (Pazopanib) 200 mg dengan minimum 2 box), dan pembelian maksimum per transaksi adalah:

          <p>(-) 6 box untuk Kryxana (Ribociclib) dengan/tanpa kombinasi Femara (Letrozole)</p>
          <p>(-) 8 box untuk Jakavi (Ruxolitinib) 5 mg, 15 mg, dan 20 mg</p>
          <p>(-) 2 box untuk Votrient (Pazopanib) 400mg</p>
          <p>(-) 3 box untuk Votrient (Pazopanib) 200mg</p>
          <p>(-) 4 box untuk Rebozet (Eltrombopag) 25 mg dan 50 mg. </p>
          
          Diperbolehkan untuk melakukan 3 kali transaksi per bulan.
        </Typography>);
      case 'RG_SOC':
      case 'RG-SOC': 
        return (<Typography>
          Untuk program ini, terdapat diskon khusus untuk pembelian dosis penuh sebulan maupun pembelian 1 box. Pembelian minimum adalah 1 box (terkecuali Votrient (Pazopanib) 200 mg dengan minimum 2 box), dan pembelian maksimum per transaksi adalah:

          <p>(-) 6 box untuk Kryxana (Ribociclib) dengan/tanpa kombinasi Femara (Letrozole) </p>
          <p>(-) 8 box untuk Jakavi (Ruxolitinib) 5 mg, 15 mg, dan 20 mg</p>
          <p>(-) 2 box untuk Votrient (Pazopanib) 400mg</p>
          <p>(-) 3 box untuk Votrient (Pazopanib) 200mg</p>
          <p>(-) 4 box untuk Rebozet (Eltrombopag) 25 mg dan 50 mg. </p>
          
          Diperbolehkan untuk melakukan 3 kali transaksi per bulan.
      </Typography>);
    
      default:
        return (<Typography>
          Untuk program ini, pembelian minimum adalah 2 box, dan maksimum
          adalah 4 box per transaksi. Diperbolehkan untuk melakukan 2
          transaksi per bulan dengan kemungkinan total 8 box pembelian per
          bulan
        </Typography>);
    }
  }

  return (
    <Container className={classes.root}>
      <Typography variant="body2" paragraph>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              <b>Bagaimana cara mendaftarkan pasien?</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Untuk mendaftarkan pasien, buka ikon 'Pendaftaran pasien', dan isi
              rincian pasien. Pasien kemudian akan menerima SMS dan email untuk
              memberikan persetujuan terhadap syarat dan ketentuan yang berlaku
              sebelum terdaftar dalam program.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              <b>Bagaimana cara melakukan transaksi?</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Buka ikon 'Klaim diskon', dan masukkan kode pasien yang akan
              disediakan oleh pasien. Untuk setiap transaksi, Anda harus
              memasukkan rincian dokter dan mengunggah resep. Pilih dosis obat
              dan jumlah box yang dibeli lalu klik 'Kirim.' Diskon akan
              tergantung pada jumlah box yang dibeli.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              <b>Berapa jumlah box yang dapat dibeli pasien per transaksi?</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {renderNumberOfBoxesGuide()}
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              <b>Bagaimana mekanisme diskon?</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Diskon akan diterapkan tergantung pada jumlah box yang dibeli.
              Diskon akan diterapkan untuk setiap jumlah box genap yang dibeli
              (dihitung dari box pertama yang dibeli oleh pasien setelah
              terdaftar pada program ini)
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              <b>Mengapa kode transaksi tidak valid?</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Bisa jadi kode yang dimasukkan salah atau kadaluarsa. Pastikan
              untuk memasukkan kode 6 digit seperti yang diberikan oleh pasien
              dengan benar. Validitas kode hanya berlaku selama 120 hari.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              <b>
                Bisakah seorang pasien menggunakan kembali kode program mereka?
              </b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Tidak, setiap kode pasien adalah unik dan hanya dapat digunakan
              satu kali.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              <b>Bagaimana jika pasien kehilangan kode programnya?</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Anda dapat mendaftarkan ulang pasien ke dalam program, sehingga
              pasien dapat menerima kode program baru melalui SMS atau email
              setelah pasien memberikan persetujuannya untuk pendaftaran
              program.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              <b>Dapatkah saya memilih beberapa SKU per transaksi?</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Tidak. Anda hanya dapat memilih satu SKU(dosis) per transaksi.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              <b>Bagaimana cara saya mengubah kata sandi saya?</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Untuk mengubah kata sandi Anda, buka 'Pengaturan' dan masukkan
              kata sandi lama untuk mengganti dengan kata sandi baru
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              <b>Bagaimana saya melihat semua transaksi saya?</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Untuk memantau semua transaksi yang telah Anda lakukan di
              aplikasi, buka 'Riwayat Transaksi' untuk melihat semua pembelian
              yang dilakukan dan SKU yang dibeli serta waktu dan tanggal
              pembelian.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              <b>Bagaimana cara membatalkan transaksi? </b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Jika Anda ingin membatalkan transaksi, silakan masuk ke 'Riwayat Transaksi', 
              dan klik tanda 'centang' di samping setiap transaksi. 
              Pembatalan transaksi hanya berlaku dalam 24 jam. Setelah itu, 
              semua pembelian yang dilakukan akan dianggap valid.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              <b>
                Dapatkah saya mendaftarkan ulang pasien yang telah keluar dari
                program ini?
              </b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Ya. Anda hanya perlu memasukkan ulang nomor ponsel serta detail
              lain dari pasien untuk mendaftar.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              <b>Bisakah pasien dinonaktifkan dari program?</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Ya. Jika tidak ada pembelian dari pasien dalam waktu 4 bulan,
              pasien akan secara otomatis dikeluarkan dari program, dan tidak
              dapat mendaftar kembali.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Typography>
    </Container>
  );
};

export default withStyles(styles)(FAQContent);
