export default {
  LOGIN: 'Masuk',
  DONT_HAVE_AN_ACCOUNT_YET: 'Belum punya akun?',
  REGISTER: 'Daftar',
  REGISTER_PATIENT: 'Pendaftaran Pasien',
  REDEEM_DISCOUNT: 'Klaim Diskon',
  UPLOAD_RECEIPT: 'Unggah Kuitansi',
  TRANSACTION_HISTORY: 'Riwayat Transaksi',
  WELCOME_TO_STELLAR: 'Selamat datang di Stellar',
  SETTINGS: 'Pengaturan',
  USER_GUIDE: 'Panduan Pengguna',
  ABOUT: 'Tentang Stellar',
  TERMS_AND_CONDITIONS: 'Syarat dan Ketentuan',
  CONTACT_US_FOR_SUPPORT: 'Hubungi kami untuk bantuan',
  LOGOUT: 'Keluar',
  EDIT_PROFILE: 'Ubah Profil',
  EMAIL_ADDRESS: 'Alamat Email',
  MOBILE_NUMBER: 'Nomor Ponsel',
  RESET_PASSWORD: 'Ubah Kata Sandi',
  OLD_PASSWORD: 'Kata sandi lama',
  NEW_PASSWORD: 'Kata sandi baru',
  CONFIRM_PASSWORD: 'Ketik ulang kata sandi baru',
  SAVE_CHANGES: 'Simpan perubahan',
  PASSWORD_HINT:
    'Kata sandi harus memiliki panjang minimal 8 karakter dengan setidaknya satu huruf besar dan satu huruf kecil, dan harus menyertakan karakter spesial (#@?) dan angka. (Misal: Abc0123!)',
  OLD_PASSWORD_8_CHARS: 'Tua kata sandi harus terdiri dari minimal 8 karakter',
  OLD_PASSWORD_UPPER_CASE_CHARS:
    'Tua kata sandi harus menyertakan setidaknya satu huruf besar',
  OLD_PASSWORD_LOWER_CASE_CHARS:
    'Tua kata sandi harus menyertakan setidaknya satu huruf kecil',
  OLD_PASSWORD_NUMBERS:
    'Tua kata sandi harus menyertakan setidaknya satu angka',
  OLD_PASSWORD_SPECIAL_CHARS:
    'Tua kata sandi harus menyertakan setidaknya satu karakter khusus',
  OLD_PASSWORD_REQUIRED: 'Tua kata Sandi harus diisi',
  PASSWORD_8_CHARS: 'Kata sandi harus terdiri dari minimal 8 karakter',
  PASSWORD_UPPER_CASE_CHARS:
    'Kata sandi harus menyertakan setidaknya satu huruf besar',
  PASSWORD_LOWER_CASE_CHARS:
    'Kata sandi harus menyertakan setidaknya satu huruf kecil',
  PASSWORD_NUMBERS: 'Kata sandi harus menyertakan setidaknya satu angka',
  PASSWORD_SPECIAL_CHARS:
    'Kata sandi harus menyertakan setidaknya satu karakter khusus',
  PASSWORD_REQUIRED: 'Kata Sandi harus diisi',
  CONFIRM_PASSWORD_MATCH: 'Konfirmasi kata sandi tidak sama dengan kata sandi',
  CONFIRM_PASSWORD_8_CHARS:
    'Konfirmasi kata sandi harus terdiri dari minimal 8 karakter',
  CONFIRM_PASSWORD_UPPER_CASE_CHARS:
    'Konfirmasi kata sandi harus menyertakan setidaknya satu huruf besar',
  CONFIRM_PASSWORD_LOWER_CASE_CHARS:
    'Konfirmasi kata sandi harus menyertakan setidaknya satu huruf kecil',
  CONFIRM_PASSWORD_NUMBERS:
    'Konfirmasi kata sandi harus menyertakan setidaknya satu angka',
  CONFIRM_PASSWORD_SPECIAL_CHARS:
    'Konfirmasi kata sandi harus menyertakan setidaknya satu karakter khusus',
  CONFIRM_PASSWORD_REQUIRED: 'Konfirmasi kata Sandi harus diisi',
  FIND_TRANSACTION: 'Cari transaksi',
  MONTH: 'Bulan',
  DAY: 'Tanggal',
  YEAR: 'Tahun',
  TRANSACTION_VOID_CONFIRMATION:
    'Apakah Anda yakin ingin membatalkan transaksi ini?',
  TRANSACTION_VOIDED: 'Transaksi dibatalkan',
  NO_TRANSACTIONS: 'Tidak Ada Transaksi',
  YES: 'Ya',
  NO: 'Tidak',
  CONFIRM: 'Konfirmasi',
  CANCEL: 'Membatalkan',
  ENTER_UNIQUE_PATIENT_PROGRAM_CODE: 'Masukkan Kode Pasien',
  SUBMIT: 'Kirim',
  PRODUCT_SELECTION: 'Pilihan Produk',
  SELECT_DOSAGE: 'Pilih Dosis Obat',
  QUANTITY_PER_BOX: 'Jumlah box',
  MAXIMUM_OF_FOUR_BOXES_ALLOWED: 'Jumlah maksimum adalah 4 box',
  CONFIRM_TERMS_AND_CONDITIONS:
    'Untuk melanjutkan mohon menyetujui syarat dan ketentuan yang berlaku',
  BACK: 'Kembali',
  TAKE_PHOTO: 'Unggah Foto',
  PLEASE_CHECK_YOUR_VERIFICATION_CODE:
    'Silakan periksa email Anda untuk kode verifikasi.',
  PLEASE_INPUT_YOUR_VERIFICATION_CODE: 'Mohon masukkan kode varifikasi Anda.',
  VERIFICATION_CODE_MUST_BE_8_CHARACTERS_LONG:
    'Kode verifikasi harus terdiri dari 8 karakter',
  RESEND_VERIFICATION_CODE: 'Kirim ulang kode',
  VERIFICATION_CODE: 'Masukkan kode',
  VERIFICATION: 'Verifikasi',
  ARE_YOU_SURE_YOU_WANT_TO_LOGOUT: 'Anda yakin ingin keluar?',
  IMAGE_UPLOADED: 'Gambar berhasil diunggah',
  YOU_ARE_CURRENTLY_OFFLINE: 'Anda sedang offline',
  CHOOSE_FROM_GALLERY: 'Pilih Dari Galeri',
  TAKE_A_PICTURE: 'Ambil Foto',
  YOUR_PROFILE_HAS_BEEN_UPDATED: 'Profil Anda telah diperbarui',
  YOUR_PASSWORD_HAS_BEEN_UPDATED: 'Kata sandi Anda telah diperbarui',
  HCP_DETAILS: 'Rincian Dokter',
  VERIFICATION_EMAIL_HAS_BEEN_RESENT: 'Email verifikasi telah dikirim ulang',
  PATIENT_IS_NOW_REGISTERED: 'Pendaftaran pasien berhasil',
  TRANSACTION_CODE_INVALID: 'Kode Pasien tidak valid',
  TRANSACTION_CODE_NOT_FOUND: 'Kode pengguna tidak ditemukan',
  PATIENT_ALREADY_REGISTERED_ANOTHER_DOCTOR:
    'Pasien sudah terdaftar di apoteker lain. Jika pasien ingin mendaftar ulang ke apoteker baru, pasien harus mengirim pesan "STOP" dan mendaftar ulang. (Catatan: Pendaftaran ulang akan mengembalikan kotak yang dibeli ke 0)',
  EMAIL_VERIFIED: 'Email {{email}} telah diverifikasi',
  USER_VERIFICATION_CODE_NOT_FOUND: 'Kode pengguna tidak ditemukan',
  OLD_PASSWORD_INCORRECT: 'Kata sandi yang lama salah',
  PATIENT_REREGISTER:
    'Pasien sudah terdaftar di program. Pasien harus menunjukkan Kode Pasien untuk mendapatkan potongan harga.',
  YOUR_NEW_PASSWORD_SHOULD_NOT_MATCH_OLD_PASSWORD:
    'Kata sandi baru Anda tidak boleh sama dengan kata sandi lama Anda.',
  CONFIRM_PASSWORD_SHOULD_NOT_MATCH_OLD_PASSWORD:
    'Konfirmasi kata sandi tidak boleh sama dengan kata sandi lama Anda.',
  SELECT_SPECIALIZED_TYPE: 'Spesialisasi Dokter',
  SELECT_TABLE_SPLITTING: 'Tablet-splitting/dipotong',
  FOLLOW_PRESCRIPTION: 'Sesuai resep',
  HEART_MEDICINE: 'Spesialis Jantung',
  INTERNAL_MEDICINE: 'Spesialis Penyakit Dalam',
};
